// Import the FontAwesomeIcon component
import Typewriter from 'typewriter-effect';
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import imgBG23 from "../../assets/img/trudefi/bg/23.jpg";
import img2X5 from "../../assets/img/tru2x/5.png";
import img2X6 from "../../assets/img/tru2x/6.png";

ChartJS.register(ArcElement, Tooltip, Legend);


const data = {
    labels: ['Vault', 'Liquidity Pool', 'Seed Sale', 'Presale', 'Presale 2', 'Presale 3', 'Joint Venture', 'Marketing', 'Team', 'Staff'],
    datasets: [
        {
            label: '%',
            data: [51.01, 17.45, 1, 3, 11, 13, 1, 1.29, 1, 0.25],
            backgroundColor: [
                'rgba(3,22,52)',
                'rgba(3,54,73)',
                'rgba(3,101,100)',
                'rgba(205,179,128)',
                'rgba(232,221,203)',
                'rgba(138,155,15)',
                'rgba(248,202,0)',
                'rgba(233,127,2)',
                'rgba(189,21,80)',
                'rgba(73,10,61)',
            ],
            borderColor: [
                'rgba(3,22,52)',
                'rgba(3,54,73)',
                'rgba(3,101,100)',
                'rgba(205,179,128)',
                'rgba(232,221,203)',
                'rgba(138,155,15)',
                'rgba(248,202,0)',
                'rgba(233,127,2)',
                'rgba(189,21,80)',
                'rgba(73,10,61)',
            ],
            hoverOffset: 4,
            borderWidth: 1,
        },
    ],
};

export default function Section7()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG23 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">
                <div class="container-fluid">
				<div class="row px-5 px-xl-2 px-xl-0">
					<div class="col-xl-5 px-xl-0 align-self-center">
						<div class="row mt-5 mt-xl-0 justify-content-center">
              

							<div class="offset-xl-2 col-sm-8 col-md-4 col-lg-5 text-left text-xl-left" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
								<h5 class="mb-3">The Path Ahead: Shaping a Future Rooted in Integrity and Innovation. Our roadmap is a commitment to transparency, responsibility, and the unwavering pursuit of a world where technology empowers and uplifts all.
                </h5>
								<p class="mb-0 pb-0"></p>	
							</div>


						</div>
					</div>
					<div class="col-xl-6 px-xl-0 order-first order-xl-last justify-content-left">
              <div class="col-md-12 mt-5">
                <div class="img-wrap">
                  <img src={img2X5} alt=""/>
                </div>	
              </div>	
              <div class="col-md-12 mt-0 mb-50">
                <div class="img-wrap">
                  <img src={img2X6} alt=""/>
                </div>	
              </div>	        
					</div>	
				</div>		
			</div>	
                </div>
            </Flex>
        </>
    )
}