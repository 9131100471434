// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { Box, Divider, Grid, Col } from '@mantine/core';
import
{
    faCircleDown
} from "@fortawesome/free-solid-svg-icons";
import Typewriter from 'typewriter-effect';
import styled from "styled-components";

import imgBG21 from "../../assets/img/trudefi/bg/21.jpg";
import imgREF8 from "../../assets/img/referrals/8.png";
import imgREF9 from "../../assets/img/referrals/9.png";
import imgREF10 from "../../assets/img/referrals/10.png";

import imgHOW9_1 from "../../assets/img/howto/9-1.jpg";
import imgHOW9_2 from "../../assets/img/howto/9-2.jpg";
import imgHOW9_3 from "../../assets/img/howto/9-3.jpg";
import imgHOW9_4 from "../../assets/img/howto/9-4.jpg";

// Homepage Section2 Section
export default function Section5()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section  over-hide  z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG21 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
            <div className="text-center">
                <Flex mt="60px" justifyContent="center" flexDirection="column" alignItems="center" width="100px">
                    <Text fontSize="28px" color="#fff" bold mr="14px" >Tru2X is a TruDeFi.io development</Text>    
                </Flex>              

                <div class="container">
                    <div class="row px-5 px-xl-2 justify-content-center">
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-5 mb-5">
                            <div class="img-wrap">
                                <img src={imgREF8} alt=""/>
                            </div>	
                        </div>	

                    </div>	
                    <div class="row px-5 px-xl-2 justify-content-center">
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-5 mb-5">
                            <div class="img-wrap">
                                <img src={imgREF9} alt=""/>
                            </div>	
                        </div>	
                    </div>	    
	


                    <div class="container">

<Grid mt="50px" gutter={10}>
<Col span={6} md={3} lg={3} >
    <div class="img-wrap">
        <img src={imgREF10} alt=""/>
    </div>	
</Col>
<Col span={6} md={3} lg={3} >
<div class="img-wrap">
        <img src={imgREF10} alt=""/>
    </div>
</Col>
<Col span={6} md={3} lg={3} >
<div class="img-wrap">
        <img src={imgREF10} alt=""/>
    </div>
</Col>
<Col span={6} md={3} lg={3} >
<div class="img-wrap">
        <img src={imgREF10} alt=""/>
    </div>
</Col>
</Grid>           
</div>

                </div>		
            </div>
            </Flex>
        </>
    )
}

const Button = styled.button`
    border-radius: 8px;
    padding: 10px 18px;
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: ${({ primary }) => (primary ? "#d9511c" : "#171615")};
    color: ${({ primary }) => (primary ? "#f1ede9" : "#d9511c")};
    border: ${({ secondary }) => secondary && "1px solid #d9511c"};
`;