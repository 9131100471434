// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import
{
    faCircleDown
} from "@fortawesome/free-solid-svg-icons";
import Typewriter from 'typewriter-effect';
import imgBG21 from "../../assets/img/trudefi/bg/21.jpg";
import img2X7 from "../../assets/img/tru2x/7.png";
// Homepage Section2 Section
export default function Section5()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG21 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">
                    <div className="box-cont h-fit w-fit px-14 mb-10 py-8 shadow-md bg-gradient-to-r from-neutral-900 rounded-lg">
                        <h2 className="text-white font-bold">👨‍🚀 Sodales fermentum</h2>
                        <h4 className="lead text-white font-bold">
                            <Typewriter
                                options={{
                                    strings: ["ut.", "varius.", "porta."],
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </h4>
                        <div class="section padding-bottom-big over-hide z-bigger">
			<div class="container-fluid">
				<div class="row px-5 px-xl-2 px-xl-0">
					<div class="col-xl-6 px-xl-0 align-self-center">
						<div class="row mt-5 mt-xl-0 justify-content-center">
							<div class="offset-xl-5 col-sm-8 col-md-6 col-lg-5 text-center text-xl-right" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
								<h5 class="mb-3">Hexplainer wraps it up for you                </h5>
								<p class="mb-0 pb-0">“I’ll explain everything you need to know in this short and easy-to-understand video. However, please bear in mind that there’s no financial advice on this channel; I’m just gathering the facts so my lazy friends don’t have to.”                </p>	
							</div>
							<div class="offset-xl-5 col-sm-8 col-md-6 col-lg-5 mt-5 mt-md-0 mt-xl-5 text-center text-xl-right" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
								<button class="mb-3">Yield security</button>
								
                
							</div>

						</div>
					</div>
					<div class="col-xl-6 px-xl-0 order-first order-xl-last">
            <div class="video-section hover-target">
            <figure class="vimeo"> 
								<a href="https://player.vimeo.com/video/289474455" class="">
									<img src={img2X7} alt="image"/>
								</a>
							</figure>
						</div>	
					</div>	
				</div>		
			</div>	
		</div>
                        <a href="/whitepaper/whitepaper.pdf"
                            target="_blank"
                            className="bg-slate-300 text-black hover:bg-red-600 active:bg-red-900 font-bold uppercase text-base px-8 py-3 rounded-[24px] shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                            <span>tincidunt <FontAwesomeIcon icon={faCircleDown} className="ml-2" /></span>
                        </a>
                    </div>
                </div>
                <div></div>
            </Flex>
        </>
    )
}