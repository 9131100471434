// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import
{
    faTelegram
} from "@fortawesome/free-brands-svg-icons";
import imgBG24 from "../../assets/img/trudefi/bg/24.jpg";
import vid2 from "../../assets/img/videos/Media2.mp4";
// Homepage Section2 Section
export default function Section6()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG24 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">
                    <div className="box-cont h-fit w-fit mt-[10%] px-14 mb-10 py-8 shadow-md bg-gradient-to-r from-neutral-900 rounded-lg">
                    <div class="container">
			<div class="row px-5 px-xl-2 justify-content-center">
      <div class=" px-xl-0 order-first order-xl-first">
            <div class="video-section hover-target">
							<figure> 
                <iframe width="700" height="400" src={vid2} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</figure>
						</div>	
					</div>	
        <div class="col-lg-4 mt-5 text-center text-xl-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.1s">
								<h5 class="mb-3">Unleash the full potential of your deposit & time.                </h5>
                <ul>
                <li><p class="mb-0 pb-0">Connect with the TruDeFi community on Telegram and find competent insights and support.                </p>	</li>
                <li><p class="mb-0 pb-0">Learn about the possibilities of compounding your daily accruals.                 .</p>	</li>
                <li><p class="mb-0 pb-0">Did you know? Using a referral link for your deposits grants you a bonus of 5%; using the right one can create another residual income.                </p>	</li>
                </ul>
							</div>	
			</div>		
		</div>	
                        <a href="https://t.me/R3D4NG3L"
                            target="_blank"
                            className="bg-slate-300 mt-5 text-black hover:bg-red-600 active:bg-red-900 font-bold uppercase text-base px-8 py-3 rounded-[24px] shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                            <span>Suspendisse <FontAwesomeIcon icon={faTelegram} className="ml-2" /></span>
                        </a>
                    </div>
                </div>
            </Flex>
        </>
    )
}