import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import ScrollArea from 'react-scrollbar'
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

import useMediaQuery from '@mui/material/useMediaQuery';
import MediaQuery from 'react-responsive'
import FormGroup from '@mui/material/FormGroup'; 
import FormControlLabel from '@mui/material/FormControlLabel'; 
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay, Scrollbar, A11y } from "swiper";
import { LoadingOverlay, Loader } from 'react-overlay-loader';

import { useMemo } from 'react';

import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_ToggleFiltersButton,
} from 'mantine-react-table';
//import { Box, Button, Flex, Menu, Text, Title } from '@mantine/core';
import { Box, Menu, Title, MantineProvider , useMantineTheme, ActionIcon } from '@mantine/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { teal, blue, red, green, pink, purple } from '@mui/material/colors';
import { IconPhoto, IconSettings, IconHeart, IconChevronDown } from '@tabler/icons-react';
import toast from "react-hot-toast";

import Card from './Card';
import ReactModal from 'react-modal'
import myEpicGame from '../../../utils/MyEpicGame.json';
import awesomeGame from '../../../utils/StakingToken.json';
import { BigNumber, ethers } from "ethers";
import {
    Chain,
    Address,
    useContractWrite,
    useNetwork,
    usePrepareContractWrite,
    useWaitForTransaction,
    useFeeData,
    useBalance,
    useAccount,
    useContractReads,
  } from "wagmi";
  import FENIX_ABI from "../../../models/abi/StakingToken.json";  
  import HELPER_ABI from "../../../models/abi/HelperToken.json"; 
  import MINER_ABI from "../../../models/abi/MinerToken.json"; 
  import { fenixContract } from "../../../libraries/fenixContract";
  import { helperContract } from "../../../libraries/helperContract";
  import { minerContract } from "../../../libraries/minerContract"; 
//import { NFTGAME_CONTRACT_ADDRESS, transformCharacterData } from '../../../utils/constants';

import CharacterCard from "../Battle/CharacterCard";
import _Libby from "./_Libby";
import { Icon } from '@iconify/react';
//import { Button } from "../../../components/CommonComponents";

//Mock Data
//import { data } from '../../../models/makeData';
import styled from "styled-components";
import ReactiveButton from 'reactive-button';






/////////////////////////////////////////////////////////
import 'animsition/dist/css/animsition.min.css';
import 'animsition/dist/js/animsition.min.js';
import $ from 'jquery';


//import "./App.css";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/font-awesome.min.css";
//import "./assets/css/animsition.min.css";
import "../../../assets/css/swiper.min.css";
import "../../../assets/css/style.css";

import img1 from "../../../assets/img/project/1.jpg";
import img2 from "../../../assets/img/project/2.jpg";
import img3 from "../../../assets/img/project/3.jpg";
import img4 from "../../../assets/img/project/4.jpg";
import img5 from "../../../assets/img/project/5.jpg";
import logo from "../../../assets/img/logo.png";
import img6 from "../../../assets/img/x1.jpg";
import img7 from "../../../assets/img/x2.jpg";




let isConfirm = false


SwiperCore.use([Navigation, Autoplay]);
function StatCard({ label, separator, value, percentage, amount, unit }) {
    return (
        <FD_Wrapper_Card>
            <FD_Wrapper_Stats>
                <FD_Wrapper_StatLabel>{label}</FD_Wrapper_StatLabel>
                <FD_Wrapper_StatSeparator>{separator}</FD_Wrapper_StatSeparator>
                <FD_Wrapper_StatValue>
                    <span>{value}</span>
                    <span style={{ color: "rgba(194, 183, 177, 1)" }}>
                        {" "}
                        {percentage}{" "}
                    </span>
                </FD_Wrapper_StatValue>
            </FD_Wrapper_Stats>
            <FD_Wrapper_Amount>
                {amount.slice(0, -1)}
                <FD_Wrapper_ImportantValue
                    style={{
                        color:
                            amount.charAt(amount.length - 1) === "M"
                                ? "rgba(199, 185, 178, 1)"
                                : "rgba(217, 81, 28, 1)",
                    }}
                >
                    {amount.charAt(amount.length - 1)}
                </FD_Wrapper_ImportantValue>
            </FD_Wrapper_Amount>
        </FD_Wrapper_Card>
    );
}

const SelectCharacter = ({ bonusWallets, characterNFT, currentDay, lastDayEntered, refetchAssets, refetchLastDay }) => {
    const isSmallScreen = useMediaQuery("(max-width:768px)");
    const { chain } = useNetwork();
    const { address } = useAccount();
    //const { data: feeData } = useFeeData({ formatUnits: "gwei", watch: false, cacheTime: 60_000 });
    const { data: fenixBalance } = useBalance({
      address: address,
      //token: fenixContract(chain).address,
      staleTime: 20_000,
    });
    const navigate = useNavigate();
    const globalTheme = useMantineTheme();

    const [cardNum, setCardNum] = useState(4)
    const [_initiators, _setInitiators] = useState([])
    const [lockedDays, setLockedDays] = useState([])
    const [gameContract, setGameContract] = useState(null);
    const [progress, setProgress] = useState("0%");

    const [Genesis, setGenesis] = useState([])
    const [Duelists, setDuelists] = useState([])
    //const [BonusYielder, setBonusYielder] = useState([])
    
    const [Initiators, setInitiators] = useState([])
    const [xInitiators, setXInitiators] = useState([])
    const [data, setData] = useState([])
    const [expand, setExpand] = useState(null)    
    const [Referrers, setReferrers] = useState([])
    const [tableUpdate, setTableUpdate] = useState(true)


    const [action, setAction] = useState([])
    const [_disable, setDisable] = useState(false)
    const [_inDay, setInDay] = useState(false)
    const [_disableField, setDisableField] = useState(false)

    const [toggle, setToggle] = useState(false)  

    const [formInput, updateFormInput] = useState({ plsAmount: 1, referrerID: 0, address: address})
    //const [renft, setRenft] = useState([])

    const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
    const [isAuctionModalOpen, setIsAuctionModalOpen] = useState(false);

    const [lobbyShow, setLobbyShow] = useState(true);
    const [lobbyType, setLobbyType] = useState(1);
    const [_waasLobby, setWaasLobby] = useState(1);
    const [lobbyWallet, setLobbyWallet] = useState(1);
    const [walletList, setWalletList] = useState([]);
    const [tokenTotals, setTokenTotals] = useState([]);
    const [todayDaily, setTodayDaily] = useState([]);
    const [minerDays, SetMinerDays] = useState([]);
    const [globals, setGlobals] = useState([]);
    const [_daily, setDaily] = useState([]);
    const [yesDaily, setYesDaily] = useState([]);
    const [_difference, setDifference] = useState(1);
    const [lastDifference, setLastDifference] = useState(1);

    const [todayMiners, setTodayMiners] = useState(0);
    const [allMiners, setAllMiners] = useState(0);

    const theme = createTheme({
      palette: {
          navbar: blue[100],
          tag: {
              red: red[200],
              pink: pink[200],
              purple: purple[200],
              blue: blue[200],
              green: green[200],
          },
      },
      typography: {
          fontFamily: [
              "NotoSans",
              "NotoSansThai",
              "Arial",
              "Roboto",
              "'Helvetica Neue'",
              "sans-serif",
          ].join(","),
      },
      shape: {
          borderRadius: 15,
      },
  });

  let formatter_6 = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 6, maximumFractionDigits: 6 });
  let formatter_4 = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 4, maximumFractionDigits: 4 });
  let formatter = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    let formatter_1 = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 1 });


  /*** CONTRACT WRITE SETUP ***/
  
  const { config: lobbyEntryConfig, isLoading: lobbyEntryIsoading } = usePrepareContractWrite({
    address: fenixContract(chain).address,
    abi: FENIX_ABI.abi,
    chainId: chain?.id,
    functionName: "xfEnter",
    args: [(formInput.referrerID).toString(), (lobbyType).toString(), formInput.address, 0],
    overrides: {
      //value: ethers.utils.parseEther(String(formInput.plsAmount)),
      value: ethers.utils.parseEther(parseFloat(formInput.plsAmount) >= 1 ? String(Number(formInput.plsAmount)) : String(0)),
      gasLimit: 14900000,
    },
    //enabled: false,
  });

  const { data: lobbyEntryWriteData, isLoading: lobbyEntryWriteLoading, write: lobbyEntryWrite } = useContractWrite({
    ...lobbyEntryConfig,
    //onSuccess(_data) {
    //  reset;
    //  setProcessing(true);
    //  setDisabled(true);
    //},
  });


  const { isLoading: entryTxIsLoaging  } = useWaitForTransaction({
    hash: lobbyEntryWriteData?.hash,
    onSuccess(data) {
      toast.success("Your entry has been initiated. Happy earning!");
      refetchAssets?.();
      refetchLastDay?.();
      refetchGlobals?.();
      refetchWaasLobby?.();
      refetchDailyData?.();
      //router.push("/lobby/active");
    },
    onError(err) {
      toast.error("Initiating entry was unsuccessful. Please try again later.");
    },    
  });




  const switchHandler = (event) => {
    if(event.target.checked == true) setLobbyType(2);
    if(event.target.checked == false) setLobbyType(1);
  };


    
    
    //const handleLogin = () => {
    //    isConfirm = true
    //    localStorage.setItem("accountStatus", "1");
    //    return connect({ connector });
    //}

    //const handleLogout = () => {
    //    isConfirm = false
    //    localStorage.removeItem("accountStatus")
    //    //disconnect();
    //}

    function copyToClipBoard() {
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
    }

    function closeModal() {
      setIsAuctionModalOpen(true)
      setIsSelectModalOpen(false);

    }

    function closeAuctionModal() {
      setIsAuctionModalOpen(false);
    }

    function writeModal() {
        setIsSelectModalOpen(false);
        lobbyEntryWrite();
    }

    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(1, 0, 0, 1)'
        },        
        content: {
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-40%, -50%)',
            right: 'auto',
            bottom: 'auto',
            backgroundColor: "transparent",
            border: 'none',
            overflow: 'hidden',
            

            position: 'absolute',


            background: '#212121',

            WebkitOverflowScrolling: 'touch',
            borderRadius: '20px',
            outline: 'none',




        },
      
    };

    ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(20,0,0,20)';

    const isMobile = useMediaQuery("(max-width: 600px)")

    const one = useMediaQuery("(max-width: 830px)");
    const two = useMediaQuery("(max-width: 1050px)");
    const three = useMediaQuery("(max-width: 1280px)");
    const four = useMediaQuery("(max-width: 1500px)");
    const five = useMediaQuery("(max-width: 1700px)");


    useEffect(() => {
      setTableUpdate(false);
    })

    useEffect(() => {
      if (_daily?.length > 0 && lockedDays?.length > 0 && characterNFT?.[0]?.lockedDay > 0)
        navigate("/");
        //window.location.reload()
    }, [address, chain])


      useEffect(() => {
        if (one){
            //console.log("...1");
            setCardNum(1)
        }
        else if (two){
            //console.log("...2");
            setCardNum(2)
        }
        else if (three){
            //console.log("...3");
            setCardNum(3)
        }
        else if (four){
            //console.log("...4");
            setCardNum(4)
        }
        else if (five){
            //console.log("...5");
            setCardNum(5)
        } else {
            setCardNum(10)
        }
    }, [one, two, three, four, five])


    var { refetch:refetchDailyData } = useContractReads({
        contracts: Array?.from(Array(currentDay)?.keys())?.map((lobby) =>    
        ({
              ...fenixContract(chain),
              functionName: "dailyData",
              args: [lobby >= 0 ? (lobby+1) : 0],     
          })
        ),
        onSuccess(data) {
          if(data?.length > 0) 
            setDaily(data);
      },        
        cacheTime: 300000,
        watch: false,
      });


      var { data: _waas, refetch:refetchWaasLobby } = useContractReads({
        contracts: Array?.from(Array(currentDay)?.keys())?.map((lobby) =>    
        ({
              ...fenixContract(chain),
              functionName: "_waasLobby",
              args: [(lobby+1) > 0 ? (lobby+1) : 1],    
          })
        ),
        cacheTime: 300000,
        watch: false,
      });





      var { refetch:refetchGlobals } = useContractReads({
        contracts: [
          {
            ...fenixContract(chain),
            functionName: "_waasLobby",
            args: [currentDay > 0 ? currentDay : 1]
          },    
          {
            ...helperContract(chain),
            functionName: "totalTokens",
          },     
          {
            ...minerContract(chain),
            functionName: "CURRENT_BONUS_WALLET",
          },       
          {
            ...minerContract(chain),
            functionName: "_minerDays",
          },      
          {
            ...fenixContract(chain),
            functionName: "globals",
          },     
          {
            ...fenixContract(chain),
            functionName: "dailyData",
            args: [currentDay > 0 ? currentDay - 1 : 0]
          },                                                                                                                                                                     
        ],
        onSuccess(data) {
          //console.log("...DATA MinerDays", data)
          if(Number(data?.[0]) > 0)
            setWaasLobby(Number(data?.[0]) / (10 ** 18));
          if(data?.[1]?.length > 0)
            setTokenTotals(data?.[1]);
          if(Number(data?.[2]) > 0)
            setLobbyWallet(Number(data?.[2]));
          if(data?.[3]?.[1]?.length > 0)
            SetMinerDays((data?.[3]?.[1]).map(x => Number(x)))
          if(data?.[4]?.length > 0)
            setGlobals(data?.[4]);
          if(data?.[5]?.length > 0)
            setYesDaily(data?.[5]);
          
          
        },   
        cacheTime: 300000,     
        watch: false,
      });






      console.log(chain);
      console.log(fenixContract(chain).address);

      //console.log("walletList", lobbyWallet, walletList);
      //console.log("bonusWallets", bonusWallets, bonusWallets?.[minerDays.indexOf(2)]?.rawAmount);
      //data?.[3]?.[1].indexOf(BigNumber.from(currentDay))
      //console.log("...fenixContract(chain),", fenixContract(chain));
      
      //console.log('...minerDays', minerDays, minerDays.indexOf(2))
      //minerDays, minerDays.indexOf(parseInt(String(2), 16)), 
      console.log('HERE characterNFT:', characterNFT)
      console.log("_daily", _daily?.length, Number(_daily?.[currentDay-1]?.lobbyPulse), _daily);
      //console.log("yesDaily", yesDaily);
      //console.log("lockedDays", lockedDays);
      //console.log("expand", expand);
      //console.log("lobbyType", (formInput.referrerID).toString(), (lobbyType).toString());
      //console.log("lastDayEntered", lastDayEntered);
      //console.log("...Duelists", characterNFT?.filter((lob) => (lob?.lockedDay === currentDay && lob?.levels === "Duelists")));
      //console.log("_waas", _waas);
      //console.log("_Initiators", Initiators?.filter((lob) => lob?._day === 3));
      //console.log("HERE ...Data", data);
      //console.log("HERE ...Initiators", Initiators);
      //console.log("xInitiators...", xInitiators);
      //console.log("Genesis", Genesis);
      //console.log("Globals", globals);
      //console.log("two", two);
      //console.log("three", three);
      //console.log("four", four);
      //console.log("five", five);
      //console.log("tokenTotals", tokenTotals);
      //console.log("formInput_LOBBY", formInput);
      //console.log("xfEnter_LOBBY", (formInput.referrerID).toString(), (lobbyType).toString(), formInput.address, 0)
      //console.log("currentDay", currentDay, Number(86400000 * currentDay), Number(Date.now().valueOf()));
      
    

    /*
    useEffect(() => {
        if (characterNFT.length > 0) {
            const characters = characterNFT.map((characterData) => transformCharacterData(characterData))
            //console.log('characters $$$$$', characters)
            setCharacters(characters)
        }

        return () => {
            if (gameContract) {
                gameContract.off('CharacterNFTMinted', onCharacterMint)
            }
        }
    }, [characterNFT])
    */



    useEffect(() => {
        if (_daily?.length > 0 && lockedDays?.length > 0 && characterNFT?.[0]?.lockedDay > 0) {
        //if (lockedDays?.length > 0 && characterNFT?.[0]?.lockedDay > 0) {

            //console.log("HERE HERE HERE");
            let chars = [];
            let gChars = [];
            let iChars = [];
            let initiatorChars = [];
            let bChars = [];
            initiatorChars.push({});
            for (var i = 0; i < (lockedDays.length); i++) {
                
                let _iChars = [];

                let _todayDaily = _daily?.filter((dal) => dal?._day === (currentDay - 1));
                if(_todayDaily?.length > 0 ) setTodayDaily(_todayDaily);

                let _lastDaily = _daily?.filter((dal) => dal?._day === (currentDay));
                if(_todayDaily?.length > 0 ) setTodayDaily(_todayDaily);
                
                let daily = _daily?.filter((dal) => dal?._day === lockedDays[i]);
                let gCard = characterNFT.filter((char) => (char.lockedDay === lockedDays[i] && char.entryType === 0));
                //let dCard = characterNFT.filter((char) => (char.lockedDay === lockedDays[i] && char.entryType === 1));
                let dCard = characterNFT.filter((char) => (char.lockedDay === lockedDays[i] && char.levels === "Duelists" && (char.entryType === 0 || char.entryType === 1) && !(char.entryType == 0 && Number(char.stakeShares) === 9999 && Number(char.entryId) < 21)));
                let _iCard = characterNFT.filter((char) => (char.lockedDay === lockedDays[i] && (char?.levels === 'Initiators' && char?.status != 10)));
                

                let lyCard = characterNFT.filter((char) => (char.lockedDay === lockedDays[i] && char.entryType === 5));


            if(daily?.[0]?._day > 0 && lyCard[0]?.lockedDay > currentDay-1){
                //console.log("...dailyLY", lockedDays[i], lyCard?.[0], daily?.[0]);
                let chrs = {...daily?.[0], ...lyCard?.[0]}
                chars.push(chrs);
           
            } else if (lyCard?.[0]?.entryType === 5 && lyCard[0]?.lockedDay > currentDay-1) {
                chars.push(lyCard?.[0])
            }




            if(daily?.[0]?._day > 0 && dCard?.[0]?.lockedDay > 0){
                //console.log("...dailyD", lockedDays[i], dCard?.[0], daily[0]);
                let chrs = {...daily?.[0], ...dCard?.[0]}
                chars.push(chrs);
            } else if (dCard?.[0]?.levels === "Duelists") {
                chars.push(dCard?.[0])
            }

            //console.log("...dailyI", lockedDays[i], _iCard[0], daily[0]);
            if(daily?.[0]?._day > 0 && _iCard[0]?.lockedDay > 0){
              //console.log("...DAILY 11111", lockedDays[i], daily?.[0], daily?.[daily?.length - 1]);
              //console.log("...dailyC", lockedDays[i], cCard[0], daily[0]);
              for (var k = 0; k < (_iCard.length); k++) {
                let chrs = {...daily?.[0], ..._iCard?.[k]}
                iChars.push(chrs);
                //if(k === 0){
                //  iChars.push(chrs)
                //}
              }
            } else if (_iCard?.[0]?.levels === "Initiators") {
              //console.log("...DAILY 22222", lockedDays[i], daily?.[0], daily?.[daily?.length - 1]);
              for (var k = 0; k < (_iCard.length); k++) {
                let chrs = {...daily?.[0], ..._iCard?.[k]}
                iChars.push(chrs);
                //if(k === 0){
                //  iChars.push(chrs)
                //}
                //console.log("...dailyC2", lockedDays?.[i], cCard?.[0], daily?.[0]);
              }} else {
                let chrs = [];
                //console.log("...DAILY 333333", lockedDays[i], daily?.[0], daily?.[daily?.length - 1]);
                //chrs = {...daily?.[daily?.length - 1], ...characterNFT?.filter((lob) => (lob?.lockedDay === currentDay && lob?.levels === "Duelists"))[0], levels: "Initiators", _dayP2XPool: _waas?.[currentDay-1], status: 1, auctionPulse: daily?.[0]?.lobbyPulse}
                chrs = {...daily?.[0], levels: "Initiators", _dayP2XPool: _waas?.[currentDay-1], status: 1, auctionPulse: daily?.[0]?.lobbyPulse}
                iChars.push(chrs);
              }


            if(_iChars?.length > 0)
              initiatorChars.push(_iChars);


        
            if(yesDaily?._day > 0 && gCard?.[0]?.lockedDay > 0){
                //console.log("...dailyG", lockedDays[i], gCard[0], daily[0]);
                let chrs = {...yesDaily, ...gCard?.[0]}
                gChars.push(chrs);
            } else if (gCard?.[0]?.entryType === 0 && gCard?.[0]?.lockedDay > 0) {
              gChars.push(gCard?.[0])
              //console.log("...dailyG2", lockedDays?.[i], gCard, daily?.[0]);
            }
            //console.log("...gCard", gCard);
            
              }

              //console.log("chars", chars);
            if(gChars?.length > 0) 
              setGenesis(gChars.filter((character) => character?.entryType === 0));
            if(chars?.length > 0)
              setDuelists(chars.filter((character) => (character?.levels === 'Duelists')));

            if(iChars?.length > 0)                     
              setInitiators(iChars.filter((character) => (character?.levels === 'Initiators' && character?.status != 10) ));
              //setData(iChars.filter((character) => (character?.levels === 'Initiators' && character?.status != 10) ));
              //setInitiators(iChars.filter((character) => (character?.levels === 'Initiators' ) ));
              //const InitiatorsCount = Initiators.length;
            if(chars?.length > 0) 
              setReferrers(chars.filter((character) => character?.levels === 'Referrers'));
              //const ReferrersCount = Referrers.length;

              if(xInitiators.length === 0 || tableUpdate == true)
                {
                setXInitiators(initiatorChars);
                //setData(cChars);
                
                }       
                 

        } else {
          let chrs = [];
          let iChars = [];
          chrs = {..._daily?.[_daily?.length - 1], levels: "Initiators", _dayP2XPool: _waas?.[currentDay-1], status: 1, auctionPulse: _daily?.[_daily?.length - 1]?.[0]?.lobbyPulse}
          iChars.push(chrs);
          setInitiators(iChars);
        }

    }, [lockedDays, _daily, yesDaily, characterNFT, walletList, tableUpdate])








    useEffect(() => {
      let iChars = [];
      let _todayMiners = 0;
      let _allMiners = 0;
      //if(xControllers?.[0]?.[0]?.dividend > 0 && xControllers?.[0]?.[0]?.numItems > 0){
        let lobby_now = Duelists?.filter((lob) => lob?._day === currentDay);  
        let lobby_not_triggered = Duelists?.filter((lob) => lob?._day === currentDay - 1);  
        for (var j = 1; j < currentDay + 1; j++) {
          let rawAmt = 0;
          let daily = _daily?.filter((dal) => dal?._day === j);
          
          let lobby = Initiators?.filter((lob) => lob?._day === j);

          let _cCard = characterNFT.filter((char) => ((Number(char?.lockedDay) % 100000) === j && char?.levels === 'Controllers'));
          

          //console.log("HERE ...dailyI", j, daily, lobby, lobby_now);
          console.log("HERE DEPOSIT", j, _cCard, _cCard.length);

          let date = (Number(Date.now().valueOf()) - Number(86400000 * (currentDay - j)));
          

          if(lobby?.[0]?.lockedDay == j){
            //console.log("HERE 00000");
            iChars.push({...lobby?.[0], date: date, signal: '00000', _prevDayP2XPool: _waas?.[j], items: Initiators?.filter((lob) => lob?.lockedDay === j)?.length});
          } else {
              let chrs = [];
              if(j === currentDay - 1 && lobby_not_triggered?.[0]?.lockedDay === j && lobby_not_triggered?.[0]?.status != 8){
                //console.log("HERE 99999");
                chrs = {...lobby_not_triggered?.[0], ...lobby?.[0], levels: 'Initiators', signal: '99999', _prevDayP2XPool: _waas?.[j], date: date, status: 1, items: 1}
              } else if(j === currentDay  && lobby_not_triggered?.[0]?.lockedDay === j){
                //console.log("HERE 88888");
                chrs = {...lobby_not_triggered?.[0], ...lobby?.[0], levels: 'Initiators', signal: '88888', _prevDayP2XPool: _waas?.[j], date: date, status: 1, items: 1}
              }  else if(j === currentDay && lobby_now?.[0]?.lockedDay === j){
                //console.log("HERE 11111");
                chrs = {...lobby_now?.[0], ...lobby?.[0], levels: 'Initiators', signal: '11111', _prevDayP2XPool: _waas?.[j], date: date, lockedDay: currentDay, status: 1, auctionPulse: daily?.[0]?.lobbyPulse, items: 1}
              } else if (j === currentDay && lobby?.[0]?._day === j) {
                //console.log("HERE 22222");
                chrs = { ...lobby?.[0], date: date, lockedDay: currentDay, signal: '22222', _prevDayP2XPool: _waas?.[j], status: 1, auctionPulse: daily?.[0]?.lobbyPulse, items: 1}
              } else {
                  //console.log("HERE 33333");
                  if(_cCard.length == 1){
                    if(Number(_cCard?.[0]?.stakedDays) != 2222) // 2222 here is the stakedDays contract value when miner is via referals... not to be confuse with signal: '11111'.
                        rawAmt = (Number(_cCard?.[0]?.rawAmount) * ((Number(_cCard?.[0]?.minersMod) - (Number(_cCard?.[0]?.minersMod) % 100)) / 1000 ));
                        if(((Number(_cCard?.[0]?.lockedDay) - (Number(_cCard?.[0]?.lockedDay) % 100000)) / 100000 ) === currentDay)
                            _todayMiners += (Number(_cCard?.[0]?.minersMod) % 100);
                        _allMiners += (Number(_cCard?.[0]?.minersMod) % 100);
                  } else {
                    for (var k = 0; k < _cCard.length; k++) {
                        if(Number(_cCard?.[k]?.stakedDays) != 2222)
                            rawAmt += (Number(_cCard?.[k]?.rawAmount) * ((Number(_cCard?.[k]?.minersMod) - (Number(_cCard?.[k]?.minersMod) % 100)) / 1000 ));
                        if(((Number(_cCard?.[k]?.lockedDay) - (Number(_cCard?.[k]?.lockedDay) % 100000)) / 100000 ) === currentDay)
                            _todayMiners += (Number(_cCard?.[k]?.minersMod) % 100);
                        _allMiners += (Number(_cCard?.[k]?.minersMod) % 100);
                    }
                  }
                  chrs = {...daily?.[0], date: date, lockedDay: j, signal: '33333', _prevDayP2XPool: _waas?.[j],  rawAmount: rawAmt, status: 10, items: 1}            
              }
              
              iChars.push(chrs)
              

          }
          //console.log("HERE ...iChars", j, iChars);
          
        }
        
        
        console.log("TOTALS", _todayMiners, _allMiners);
        setTodayMiners(_todayMiners);
        setAllMiners(_allMiners);


        const siChars = iChars.sort((a, b) => b.lockedDay - a.lockedDay);
        if(iChars?.length > 0) {
          setData(iChars);
          setTableUpdate(false);
        }
      //}
    }, [Initiators, tableUpdate])

 





    useEffect(() => {
        if (characterNFT.length > 0) {
            let _lockedDays = Array.from(new Set(characterNFT?.map(item => item.lockedDay)));
            //if (Number(_lockedDays?.[0]) >= 1) {
              //if (currentDay == lastDayEntered?.[0]?.day && characterNFT?.[characterNFT?.length - 1]?.status != 10)
              //  setLockedDays([..._lockedDays]);
              //else
                //setLockedDays([..._lockedDays, currentDay]);

                setLockedDays(Array.from(new Set([..._lockedDays, currentDay])));
                
            //}
            //console.log('__lockedDays:', currentDay, lockedDays)
            //console.log('....Initiators:', Initiators)
        } else setLockedDays([currentDay]);
    }, [characterNFT])


    useEffect(() => {
      //if ((Duelists.length > 0 || Initiators.length > 0) && currentDay == lastDayEntered?.[0]?.day) {
      //console.log("clDuelists...", Duelists, currentDay, lastDayEntered)
      if (Duelists.length > 0 && currentDay == lastDayEntered?.[0]?.day) {
          setInDay(true);
        } else {
            setInDay(false);
        }
    }, [Duelists, currentDay, lastDayEntered?.[0]])    



    useEffect(() => {
      //console.log("walletList", bonusWallets, lobbyWallet);
      if (bonusWallets?.length > 0 && Number(lobbyWallet) > 0) {
        let BW = [];
        let LOCAL_BONUS_WALLET = Number(lobbyWallet);
        let walletCount = 5;
        do {
          //console.log("LOCAL_BONUS_WALLET", LOCAL_BONUS_WALLET);
          BW.push(LOCAL_BONUS_WALLET);
          //LOCAL_BONUS_WALLET = LOCAL_BONUS_WALLET > 1 ? LOCAL_BONUS_WALLET -= 1 : 10;
          LOCAL_BONUS_WALLET > 1 ? LOCAL_BONUS_WALLET -= 1 : LOCAL_BONUS_WALLET = 10;
          walletCount -= 1;
        } while (walletCount > 0);
      setWalletList(BW);  
      } 
  }, [bonusWallets, lobbyWallet])   




    const columns = useMemo(
        () => [
            {
                id: "employee", //id used to define `group` column
                header: "Pool Info",
                columns: [
                    {
                        accessorFn: (row) => {
                            //convert to Date for sorting and filtering
                            //const sDay = new Date(row.endTs);
                            const sDay = new Date(row.date);
                            //const currentTs = Math.floor(Date.now() / 1000);
                            //const sDay = new Date(row.endTs * 1000);
                            //sDay.setHours(0, 0, 0, 0); // remove time from date (useful if filter by equals exact date)
                            return sDay;
                        },
                        id: "startDate",
                        header: "Start Date",
                        //filterVariant: 'date-range',
                        sortingFn: "datetime",
                        //enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
                        Cell: ({ cell }) =>
                            cell.getValue().toLocaleDateString(), //render Date as a string
                        Header: ({ column }) => (
                            <em>{column.columnDef.header}</em>
                        ), //custom header markup
                    },
                    {
                        accessorFn: (row) => `${row.lockedDay ?? currentDay}`, //accessorFn used to join multiple data into a single cell
                        id: "day", //id is still required when using accessorFn instead of accessorKey
                        header: "Day",
                        size: 50,
                        //filterVariant: 'autocomplete',
                        Cell: ({ renderedCellValue, row }) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "16px",
                                }}
                            >
                                <span>{renderedCellValue}</span>
                            </Box>
                        ),
                    },
                    {
                        accessorFn: (row) =>
                            `${formatter.format(
                                Number(row._dayP2XPool ?? 0) / 10 ** 18
                            )}`,
                        //accessorKey: '_dayP2XPool', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                        enableClickToCopy: true,
                        id: "p2xpool",
                        header: "P2X Emission",
                        size: 100,
                    },
                    {
                        accessorFn: (row) => {
                            if (
                                Number(row._dayP2XPool) > 0 &&
                                Number(row.auctionPulse) > 0
                            ) {
                                return formatter_6.format(
                                    Number(row._dayP2XPool ?? 0) /
                                    10 ** 18 /
                                    (Number(row.auctionPulse ?? 0) /
                                        10 ** 18)
                                );
                            } else return formatter.format(0);
                        },
                        //accessorFn: (row) => `${formatter_6.format((Number(row._dayP2XPool ?? 0) / 10 ** 9) / (Number(row.auctionPulse ?? 0) / 10 ** 18))}`,
                        id: "ratio", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                        enableClickToCopy: true,
                        header: "P2X / PLS",
                        size: 100,
                    },
                    {
                        accessorFn: (row) =>
                            `${formatter_4.format(
                                Number(row.auctionPulse ?? 0) / 10 ** 18
                            )}`,
                        //accessorKey: 'total_entries', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                        enableClickToCopy: true,
                        id: "dayentries",
                        header: "Day Entries",
                        size: 100,
                    },
                ],
            },
            {
                id: "id",
                header: "Your Info",
                columns: [
                    {
                        accessorFn: (row) => {
                            if (
                                Number(row._dayP2XPool) > 0 &&
                                Number(row.rawAmount) > 0 &&
                                Number(row.auctionPulse) > 0
                            ) {
                                return `${formatter.format(
                                    ((Number(row._dayP2XPool) / 10 ** 18) ) *
                                        (Number(row.rawAmount) / 10 ** 18) /
                                        (Number(row.auctionPulse) / 10 ** 18 )
                                )}`;
                                {
                                    /*${(Number(row._dayP2XPool) / 10 ** 9)} ${(Number(row.rawAmount) / 10 ** 18)} ${(Number(row.auctionPulse) / 10 ** 18)} ${(Number(row.xfYield) / 10 ** 18)}*/
                                }
                            } else return formatter.format(0);
                        },
                        header: "Receiving+",
                        size: 100,
                    },
                    {
                        accessorFn: (row) => {
                            if (row.items > 1 && row[1] != "Duelists") {
                                return `${formatter_4.format(
                                    Number(row.rawAmount ?? 0) / 10 ** 18
                                )} → ( 1 of ${row.items} )`;
                            } else {
                                return `${formatter_4.format(
                                    Number(row.rawAmount ?? 0) / 10 ** 18
                                )}`;
                            }
                        },
                        id: "yourentries",
                        header: "Your Entries",
                        //filterVariant: 'multi-select',
                        size: 100,
                    },
                    {
                        accessorFn: (row) => {
                            const _status = [
                                "WAITLIST",
                                "ACTIVE",
                                "COLLECT",
                                "REFERRED",
                                "MINING",
                                "ONEFIVEX",
                                "UNLOCKED",
                                "UNLOCKED2X",
                                "COMPLETED",
                                "REFERRAL",
                                "ENDED",
                            ];
                            return _status[row.status];
                        },
                        id: "status",
                        header: "Status",
                        //filterVariant: 'date-range',
                        sortingFn: "datetime",
                        //enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
                        Cell: ({ cell }) => (
                            <Box
                                sx={(theme) => ({
                                    backgroundColor:
                                        cell.getValue() == "ACTIVE"
                                            ? theme.colors.green[9]
                                            : cell.getValue() == "COLLECT"
                                            ? theme.colors.yellow[9]
                                            : theme.colors.red[9],
                                    borderRadius: "4px",
                                    color: "#fff",
                                    maxWidth: "9ch",
                                    padding: "4px",
                                    display: "flex",
                                    justifyContent: "center",
                                })}
                            >
                                {cell.getValue()?.toLocaleString?.("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}
                            </Box>
                        ),
                        Header: ({ column }) => (
                            <em>{column.columnDef.header}</em>
                        ), //custom header markup
                    },
                ],
            },
        ],
        []
    );

    const table = useMantineReactTable({
        columns,
        data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        initialState: { pagination: { pageSize: 25 } },
        //enableColumnFilterModes: true,
        //enableColumnOrdering: true,
        enableFacetedValues: true,
        //enableGrouping: true,
        //enablePinning: true,
        enableRowActions: true,
        enableRowSelection: true,
        paginationDisplayMode: "default",
        positionToolbarAlertBanner: "bottom",
        mantinePaperProps: {
            //style: { 'border': '1px solid #40C057' },
            style: {
                border: "0px solid #40C057",
                marginTop: "24px",
                backgroundColor: "transparent",
                borderRadius: "14px",
            },
        },

        mantineTableProps: {
            striped: "even",
            style: {
                backgroundColor: "#221f1d", // Change the background color of the table
            },
        },

        mantineTableBodyRowProps: {
            style: { border: "5px solid #212121" },
        },
        mantineTableBodyCellProps: {
            //easier way to create media queries, no useMediaQuery hook needed.
            style: {
                fontSize: "16px",
                fontWeight: "500",
                "@media (min-width: 600px)": {
                    fontSize: "12px",
                },
            },
        },
        mantineTableHeadCellProps: {
            //easier way to create media queries, no useMediaQuery hook needed.
            style: {
                fontSize: "18px",
                fontWeight: "700",
                "@media (min-width: 600px)": {
                    fontSize: "12px",
                },
            },
        },
        mantineTableBodyRowProps: ({ row }) => ({
            style: {
                backgroundColor: "transparent",
            },
        }),
        mantineTableHeadRowProps: ({ row }) => ({
            style: {
                backgroundColor: "transparent",
            },
        }),
        mantinePaginationProps: {
            radius: "xl",
            size: "lg",
            position: "center",
            style: {
                backgroundColor: "#221f1d", // Change the background color of the table
            },
            classNames: {
                root: "pagination-container",
                item: "pagination-button",
                select: "pagination-select",
            },
        },

        mantineExpandButtonProps: ({ row }) => ({
            children: (
                <>
                    <Box
                        sx={(theme) => ({
                            backgroundColor:
                                row.original.status == 0
                                    ? theme.colors.green[9]
                                    : row.original.status == 2
                                    ? theme.colors.yellow[9]
                                    : row.original.status == 10
                                    ? theme.colors.red[9]
                                    : theme.colors.green[9],
                            borderRadius: "4px",
                            color: "#fff",
                            //width: '100px',
                            padding: "4px",
                            flex: 1,
                        })}
                    ></Box>
                    <ActionIcon>
                        <IconChevronDown
                            sx={(theme) => ({
                                "& style": {
                                    // Doesnt work
                                    color:
                                        row.original.status == 4
                                            ? "blue"
                                            : row.original.status == 5
                                            ? "yellow"
                                            : row.original.status == 7
                                            ? "green"
                                            : "pink",
                                },
                            })}
                        />
                    </ActionIcon>
                </>
            ),
        }),

        //mantineSearchTextInputProps: {
        //  placeholder: 'Search Employees',
        //},

        renderDetailPanel: ({ row }) =>
            row.original.groupId > 0 && row.original.entryId > 0 ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "left",
                        gap: "16px",
                        padding: "16px",
                        width: "100%",
                    }}
                >
                    {/* <Box sx={{ textAlign: "left" }}>
                        <Title>Entry Ianfo:</Title>
                        <Flex
                            flexDirection="column"
                            className=""
                            alignItems="left"
                            justifyContent="left"
                        >
                            <Flex>
                                <Text
                                    fontSize="18px"
                                    color="#03fc13"
                                    ml="3px"
                                    bold
                                >
                                    {"Index ID: "}
                                </Text>
                                <Text fontSize="18px" ml="3px" bold>
                                    {Number(row.original.groupId)}
                                </Text>
                            </Flex>
                            <Flex>
                                <Text
                                    fontSize="18px"
                                    color="#03fc13"
                                    ml="3px"
                                    bold
                                >
                                    {"Entry ID: "}
                                </Text>
                                <Text fontSize="18px" ml="3px" bold>
                                    {Number(row.original.entryId)}
                                </Text>
                            </Flex>
                            <Flex>
                                <Text
                                    fontSize="18px"
                                    color="#03fc13"
                                    ml="3px"
                                    bold
                                >
                                    {"Referrer ID: "}
                                </Text>
                                <Text fontSize="18px" ml="3px" bold>
                                    {Number(row.original.referenceId)}
                                </Text>
                            </Flex>
                        </Flex>
                    </Box> */}

                    {Initiators?.filter(
                        (lob) => lob?._day === row.original._day
                    )?.map((character, index) => (
                        <Flex>
                            <Card
                                ml="10px"
                                character={character}
                                index={index}
                                levels={"Initiators"}
                                referCard={false}
                                gameContract={gameContract}
                                currentDay={currentDay}
                                lastDayEntered={lastDayEntered}
                                lobbyWallet={lobbyWallet}
                                refetchAssets={refetchAssets}
                                refetchLastDay={refetchLastDay}
                                refetchGlobals={refetchGlobals}
                                refetchDailyData={refetchDailyData}
                                refetchWaasLobby={refetchWaasLobby}
                            />
                        </Flex>
                    ))}
                </Box>
            ) : null,
        renderRowActionMenuItems: () => (
            <>
                <Menu.Item>View Profile</Menu.Item>
                <Menu.Item>Send Email</Menu.Item>
            </>
        ),
        renderTopToolbar: ({ table }) => {
            const handleDeactivate = () => {
                table.getSelectedRowModel().flatRows.map((row) => {
                    alert("deactivating " + row.getValue("name"));
                });
            };

            const handleActivate = () => {
                table.getSelectedRowModel().flatRows.map((row) => {
                    alert("activating " + row.getValue("name"));
                });
            };

            const handleContact = () => {
                table.getSelectedRowModel().flatRows.map((row) => {
                    alert("contact " + row.getValue("name"));
                });
            };

            {/*return (
                <Flex p="md" justify="space-between">
                    <Flex gap="xs">
                        import MRT sub-components
                        <MRT_GlobalFilterTextInput table={table} />
                        <MRT_ToggleFiltersButton table={table} />
                    </Flex>
                     <Flex sx={{ gap: "8px" }}>
                        <Button
                            color="red"
                            disabled={!table.getIsSomeRowsSelected()}
                            onClick={handleDeactivate}
                            variant="filled"
                        >
                            Deactivate
                        </Button>
                        <Button
                            color="green"
                            disabled={!table.getIsSomeRowsSelected()}
                            onClick={handleActivate}
                            variant="filled"
                        >
                            Activate
                        </Button>
                        <Button
                            color="blue"
                            disabled={!table.getIsSomeRowsSelected()}
                            onClick={handleContact}
                            variant="filled"
                        >
                            Contact
                        </Button>
                    </Flex> 
                </Flex>
            );*/}
        },
    });

    
    const timeUnits = [
        { value: "0", label: "Day" },
        { value: "12", label: "Hour" },
        { value: "25", label: "Min" },
        { value: "00", label: "Sec" },
    ];



    ////////////////////////////////////////////////////////////////


    
  
  
      
      useEffect(() => {
      $('.animsition').animsition({
          inClass: 'fade-in',
          outClass: 'fade-out',
          inDuration: 1500,
          outDuration: 800,
          linkElement: 'a',
          loading: true,
          loadingParentElement: 'body', 
          loadingClass: 'animsition-loading',
          loadingInner: '', 
          timeout: false,
          timeoutCountdown: 5000,
          onLoadEvent: true,
          browser: [ 'animation-duration', '-webkit-animation-duration'],
          overlay : false,
          overlayClass : 'animsition-overlay-slide',
          overlayParentElement : 'body',
          transition: function(url){ window.location.href = url; }
        });
      }, []);
  
  
  
  
      useEffect(() => {
          // Page cursors
          const handleMouseMove = (n) => {
            if (t && e && i) {
              t.style.left = n.clientX + "px";
              t.style.top = n.clientY + "px";
              e.style.left = n.clientX + "px";
              e.style.top = n.clientY + "px";
              i.style.left = n.clientX + "px";
              i.style.top = n.clientY + "px";
            }
          };
      
          const t = document.getElementById('cursor');
          const e = document.getElementById('cursor2');
          const i = document.getElementById('cursor3');
      
          const addHover = () => {
            if (e && i) {
          e.classList.add('hover');
          i.classList.add('hover');
            }
        };
    
        const removeHover = () => {
            if (e && i) {
          e.classList.remove('hover');
          i.classList.remove('hover');
            }
        };
      
          document.body.addEventListener('mousemove', handleMouseMove);
      
          const hoverTargets = document.querySelectorAll('.hover-target');
          hoverTargets.forEach((target) => {
            target.addEventListener('mouseover', addHover);
            target.addEventListener('mouseout', removeHover);
          });
      
          // Parallax & fade on scroll
          const scrollBanner = () => {
            const handleScroll = () => {
              const scrollPos = window.scrollY;
              const parallaxFadeTop = document.querySelector('.parallax-fade-top');
              const parallaxTopShadow = document.querySelector('.parallax-top-shadow');
              const parallaxTop = document.querySelector('.parallax-top');
      
              if (parallaxFadeTop) {
                parallaxFadeTop.style.cssText = `top: ${scrollPos / 2}px; opacity: ${1 - scrollPos / 300};`;
              }
      
              if (parallaxTopShadow) {
                parallaxTopShadow.style.top = `${scrollPos / -2}px`;
              }
      
              if (parallaxTop) {
                parallaxTop.style.top = `${scrollPos / 2.2}px`;
              }
            };
      
            document.addEventListener('scroll', handleScroll);
          };
      
          scrollBanner();
      

      
          // Hero slider mouse events
          const heroSlider = document.getElementById('hero-slider');
          if (heroSlider) {
            heroSlider.addEventListener('mousedown', () => document.body.classList.add('scale-up'));
            heroSlider.addEventListener('mouseup', () => document.body.classList.remove('scale-up'));
          }
      
          // Navigation
          const initNavigation = () => {
            const body = document.querySelector('body');
            const menu = document.querySelector('.menu-icon');
      
            const toggleClass = (element, stringClass) => {
              if (element.classList.contains(stringClass)) {
                element.classList.remove(stringClass);
              } else {
                element.classList.add(stringClass);
              }
            };
      
            if (menu) {
              menu.addEventListener('click', () => toggleClass(body, 'nav-active'));
            }
          };
      
          initNavigation();
      
          // Scroll back to top
          const scrollToTop = () => {
            const offset = 300;
            const duration = 400;
      
            const handleScroll = () => {
              const scrollTopButton = document.querySelector('.scroll-to-top');
              if (scrollTopButton) {
                if (window.scrollY > offset) {
                  scrollTopButton.classList.add('active-arrow');
                } else {
                  scrollTopButton.classList.remove('active-arrow');
                }
              }
            };
      
            const handleClick = (event) => {
              event.preventDefault();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            };
      
            window.addEventListener('scroll', handleScroll);
      
            const scrollTopButton = document.querySelector('.scroll-to-top');
            if (scrollTopButton) {
              scrollTopButton.addEventListener('click', handleClick);
            }
          };
      
          scrollToTop();
      
          // Hero Case Study Hover Effect
          const caseStudyHoverEffect = () => {
            const handleMouseEnter = (index) => {
              const activeName = document.querySelector('.case-study-name.active');
              const showImage = document.querySelector('.case-study-images li.show');
              const newImage = document.querySelector(`.case-study-images li:nth-child(${index})`);
              const newName = document.querySelector(`.case-study-name:nth-child(${index})`);
      
              if (activeName) activeName.classList.remove('active');
              if (showImage) showImage.classList.remove('show');
              if (newImage) newImage.classList.add('show');
              if (newName) newName.classList.add('active');
            };
      
            for (let i = 1; i <= 10; i++) {
              const caseStudyName = document.querySelector(`.case-study-name:nth-child(${i})`);
              if (caseStudyName) {
                caseStudyName.addEventListener('mouseenter', () => handleMouseEnter(i));
              }
            }
      
            const firstCaseStudyName = document.querySelector('.case-study-name:nth-child(1)');
            if (firstCaseStudyName) {
              firstCaseStudyName.dispatchEvent(new Event('mouseenter'));
            }
          };
      
          caseStudyHoverEffect();
      
          // Clean up event listeners on component unmount
          return () => {
            document.body.removeEventListener('mousemove', handleMouseMove);
            hoverTargets.forEach((target) => {
              target.removeEventListener('mouseover', addHover);
              target.removeEventListener('mouseout', removeHover);
            });
          };
        }, []);
  
  
  


    return (
        <ScrollArea
            speed={1}
            smoothScrolling
            verticalContainerStyle={{ borderRadius: "10px" }}
            verticalScrollbarStyle={{ borderRadius: "10px" }}
            
        >

<>


<div class="shadow-title parallax-top-shadow">The Lobby</div>
		
		<div class="section padding-page-top padding-bottom over-hide z-bigger">
			<div class="container z-bigger">
				<div class="row page-title px-5 px-xl-2">
					<div class="col-12 parallax-fade-top">
						<h1>The Lobby</h1>
					</div>
					<div class="offset-1 col-11 parallax-fade-top mt-2 mt-sm-3">
						<p>auction entry, Welcome!</p>
					</div>
				</div>
			</div>
		</div>

		<div class="section padding-bottom-big over-hide z-bigger">
			<div class="container">
				<div class="row px-5 px-xl-2 justify-content-center">
					<div class="col-12 mb-5">
						<div class="img-wrap">
							
                            <Flex flexDirection="column" mt="60px" mb="36px">
                    <DashInfoHeading>
                            <Flex flexDirection="row" mb="40px">
                                <Text
                                    fontSize="36px"
                                    color="#fff"
                                    bold
                                    mr="14px"
                                >
                                    Dashboard
                                </Text>
                                <Text fontSize="36px" color="#FF6E36">
                                    P2X
                                </Text>
                            </Flex>
                        </DashInfoHeading>



                        <Flex
                            alignItems="left"
                            justifyContent="space-between"
                            mb="20"
                        >
                            <DashInfoWrapper>
                                <BoxLeft>
                                    <InfoBoxWrapper>
                                        <Flex
                                            flexDirection={
                                                isSmallScreen ? "column" : "row"
                                            }
                                            justifyContent="space-between"
                                        >
                                            
                                                <InfoBox>
                                                    
                                                    <ContentInfo>
                                                        {Number(
                                                            tokenTotals?.miners ?? 0
                                                        )}
                                                        <Flex flexDirection='column' justifyContent="space-between" alignItems='baseline'>
                                                            <HeadingInfo>
                                                                Total User Mines (Cards)
                                                            </HeadingInfo>
                                                            <Flex flexDirection='row' mt="3px">
                                                                <Text ml="5px" mr="1px" color="yellow" >({"Your Miners "}</Text>
                                                                <Text ml="5px" ml="8px" color="white" > {allMiners+" | Today: "+todayMiners} )</Text>
                                                            </Flex>
                                                        </Flex>
                                                    </ContentInfo>

                                                </InfoBox>
                                            

                                            <InfoBox>
                                                <Flex flexDirection='row' justifyContent="space-between" alignItems='baseline'>
                                                    <ContentInfo alignItems='end'>
                                                        {/* {Number(
                                                        todayDaily?.[0]
                                                            ?.auctionPulse
                                                    )
                                                        ? Number(
                                                            tokenTotals?.lobbies
                                                        )
                                                        : 0} */}
                                                        {formatter.format(Number(
                                                        (globals?.totalEntries ?? 0) / (10 ** 18) ??
                                                            0
                                                        ))}
                                                    </ContentInfo>
                                                </Flex>  
                                                <Flex flexDirection='column' justifyContent="space-between" alignItems='baseline'>
                                                    <HeadingInfo>
                                                        Total Lobby Entries
                                                    </HeadingInfo>
                                                    <Flex flexDirection='row' mt="3px">
                                                        <Text ml="0px"  color="white" > ({formatter_4.format((Number(_daily?.[currentDay-1]?.lobbyPulse) / 10 ** 18))}</Text>
                                                        <Text ml="5px"  color="yellow" >{"Today"}</Text>)                                                
                                                    </Flex>                                                
                                                </Flex>                                                
                                            </InfoBox>
                                            <InfoBox>
                                                <ContentInfo>
                                                {formatter.format(Number(globals?.nextStakeSharesTotal ?? 0) / (10 ** 18))}
                                                </ContentInfo>
                                                <HeadingInfo>
                                                    Today's Miner Locked P2X
                                                </HeadingInfo>
                                            </InfoBox>
                                        </Flex>
                                    </InfoBoxWrapper>
                                    <InfoBoxWrapper>
                                        <Flex
                                            flexDirection={
                                                isSmallScreen ? "column" : "row"
                                            }
                                            justifyContent="space-between"
                                        >
                                            <InfoBox>
                                                <ContentInfo>
                                                
                                                    {formatter.format(
                                                        _waasLobby
                                                    )}
                                                </ContentInfo>
                                                
                                                <HeadingInfo>
                                                <Flex flexDirection='row' justifyContent="space-between" alignItems='baseline'>
                                                    Today's P2X Pool {" "}(
                                                      <Text ml="5px" mr="1px" color="yellow" >{"Day " + currentDay}</Text>
                                                    )
                                                    </Flex>    
                                                </HeadingInfo>
                                                
                                            </InfoBox>
                                            <InfoBox>
                                                <ContentInfo>
                                                {formatter.format(((Number(isNaN(yesDaily?.auctionPulse) ? 0 : yesDaily?.auctionPulse) ?? 0) / 10 ** 18) * 0.8) }
                                                </ContentInfo>
                                                <HeadingInfo>
                                                    Today's Available PLS
                                                </HeadingInfo>
                                            </InfoBox>
                                            <InfoBox>
                                                <ContentInfo>
                                                {formatter.format(Number(globals?.stakeSharesTotal ?? 0) / (10 ** 18))}
                                                </ContentInfo>
                                                <HeadingInfo>
                                                    Total Miner locked P2X
                                                </HeadingInfo>
                                            </InfoBox>
                                        </Flex>
                                    </InfoBoxWrapper>
                                </BoxLeft>
                                <BoxRight>
                                    <Flex flexDirection="column">
                                        <CountdownContainer>
                                            <Header>
                                                <EndsInText>Ends In</EndsInText>
                                                <ClockIcon
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/157b78abcb0c290624ee6b701ade05be3310be878ee263342ec88371f8de6beb?apiKey=c2eace46523148b195c70f9101a6de88&"
                                                />
                                            </Header>
                                            <TimeContainer>
                                                {timeUnits.map(
                                                    (unit, index) => (
                                                        <React.Fragment
                                                            key={unit.label}
                                                        >
                                                            <TimeUnit>
                                                                <TimeValue>
                                                                    {unit.value}
                                                                </TimeValue>
                                                                <TimeLabel>
                                                                    {unit.label}
                                                                </TimeLabel>
                                                            </TimeUnit>
                                                            {index <
                                                                timeUnits.length -
                                                                    1 && (
                                                                <DividerIcon
                                                                    loading="lazy"
                                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd478029cbe411d689676816713fb92971dbbb9da111e4d020922bc82454c908?apiKey=c2eace46523148b195c70f9101a6de88&"
                                                                />
                                                            )}
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </TimeContainer>
                                        </CountdownContainer>
                                    </Flex>
                                </BoxRight>
                            </DashInfoWrapper>



                        
                            

                        </Flex>


                        {/*<Flex  alignItems='left' justifyContent='space-between' mb='20'>
                      <Swiper
                        modules={[Navigation, Pagination,  Scrollbar, A11y ]}
                        spaceBetween={8}
                        slidesPerView={4}
                        //navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}>

                        <SwiperSlide key={1}>
                            <Flex flexDirection='column'>   
                                
                            <Button className={`animateButton mb-10 mt-10 btn_Initiators}`} onClick={() => setIsAuctionModalOpen(true)}>Add to Lobby</Button>
                            </Flex>
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                            <Flex flexDirection='column'>
                            </Flex>
                        </SwiperSlide>

                        <SwiperSlide key={3}>
                            <Flex flexDirection='column'>
                            </Flex>                                              
                        </SwiperSlide>

                        <SwiperSlide key={4} class="swiper-slide_third">
                            <Flex flexDirection='column' mt='30' mb='10'>
                            </Flex>
                          </SwiperSlide>
                        </Swiper>
                  </Flex>*/}
                    </Flex>                            
						</div>	
					</div>	

				</div>		
			</div>	

			<div class="container">
            <div class="row px-5 px-xl-2 justify-content-center">
            <Text
                                    fontSize="36px"
                                    color="#fff"
                                    bold
                                    mr="14px"
                                >
                                    New to the Lobby?
                                </Text>                
                                </div>
				<div class="row px-5 px-xl-2 justify-content-center">
                    
					<div class="col-md-6 mt-5">
						<div class="img-wrap">
							<img src={img4} alt=""/>
						</div>	
					</div>	
					<div class="col-md-6 mt-5">
						<div class="img-wrap">
							<img src={img5} alt=""/>
						</div>	
					</div>
					<div class="col-lg-8 mt-5 text-center" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.1s">
						<p class="mb-0 pb-0 lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni.</p>
					</div>

				</div>		
			</div>		
            {/* <Loader fullPage loading={tableUpdate} /> */}

            {currentDay > 0 && (
                <Flex mt="160px" flexDirection="column">



                    {lobbyShow == true ? (
                        <>
                            {/* <Link onClick={() => setLobbyShow(false)}>
                                <Text
                                    className="widthsection"
                                    fontSize="16px"
                                    color="grey"
                                    mb="36px"
                                    mt="30px"
                                    bold
                                >
                                    HIDE Lobby
                                </Text>
                            </Link> */}
                            <Flex
                                className="hidden"
                                flexDirection="column"
                                mb="24px"
                            >
                                {true && (
                                    <>
                                        <LobbyDayOuterWrapper>
                                            <LD_WP_Title>
                                                Auction Lobby
                                            </LD_WP_Title>                                            
                                            <LobbyDayWrapper>
                                                <LD_WP_AuctionLobbyContainer>
                                                    <LD_WP_AuctionLobbyContainer_Inner>
                                                        <LD_WP_LobbyInfo>
                                                            <LD_WP_LobbyDay>
                                                                Lobby Day
                                                            </LD_WP_LobbyDay>
                                                            <LD_WP_LobbyCount>
                                                                {currentDay}
                                                            </LD_WP_LobbyCount>
                                                        </LD_WP_LobbyInfo>
                                                        <LD_WP_Description>
                                                            Join the Auction
                                                            Lobby! Participate
                                                            daily, manage your
                                                            entries, and
                                                            maximize your
                                                            rewards. Stay
                                                            engaged and keep
                                                            track of your
                                                            progress every day.
                                                        </LD_WP_Description>
                                                        <LD_WP_Divider />
                                                        <LD_WP_StatusSection>
                                                            <p>Status</p>
                                                            <LD_WP_StatusInfo>
                                                                {_inDay ===
                                                                    true && (
                                                                    <LD_WP_StatusIconContainer>
                                                                        <LD_WP_Icon
                                                                            loading="lazy"
                                                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ab6b74a08c7a04cdf01d251a0b389bf845d91da9ec88c6f4f2a3eb26808afcba?apiKey=c2eace46523148b195c70f9101a6de88&"
                                                                        />
                                                                    </LD_WP_StatusIconContainer>
                                                                )}
                                                                <LD_WP_StatusMessage
                                                                    onClick={() => (
                                                                        closeAuctionModal(),
                                                                        setIsSelectModalOpen(
                                                                            true
                                                                        )
                                                                    )}
                                                                >
                                                                    {_inDay ===
                                                                    true ? (
                                                                        `Your
                                                                participation status is
                                                                confirmed!`
                                                                    ) : (
                                                                        <ReactiveButton
                                                                            idleText={
                                                                                <Flex alignItems="center">
                                                                                    <Text
                                                                                        fontSize="12px"
                                                                                        ml="3px"
                                                                                    >
                                                                                        Enter
                                                                                        Lobby
                                                                                    </Text>
                                                                                </Flex>
                                                                            }
                                                                            size="large"
                                                                        />
                                                                    )}
                                                                </LD_WP_StatusMessage>
                                                            </LD_WP_StatusInfo>
                                                        </LD_WP_StatusSection>
                                                    </LD_WP_AuctionLobbyContainer_Inner>
                                                </LD_WP_AuctionLobbyContainer>
                                                <LD_WP_Card1>
                                                    <Flex
                                                        
                                                        flexDirection="row"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {Duelists.map(
                                                            (
                                                                character,
                                                                index
                                                            ) => (
                                                                <Flex
                                                                    mr="10px"
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <Card
                                                                        ml="10px"
                                                                        character={
                                                                            character
                                                                        }
                                                                        index={
                                                                            index
                                                                        }
                                                                        levels={
                                                                            "Duelists"
                                                                        }
                                                                        referCard={
                                                                            false
                                                                        }
                                                                        gameContract={
                                                                            gameContract
                                                                        }
                                                                        currentDay={
                                                                            currentDay
                                                                        }
                                                                        lastDayEntered={
                                                                            lastDayEntered
                                                                        }
                                                                        lobbyWallet={
                                                                            lobbyWallet
                                                                        }
                                                                        refetchAssets={
                                                                            refetchAssets
                                                                        }
                                                                        refetchLastDay={
                                                                            refetchLastDay
                                                                        }
                                                                        refetchGlobals={
                                                                            refetchGlobals
                                                                        }
                                                                        refetchDailyData={
                                                                            refetchDailyData
                                                                        }
                                                                        refetchWaasLobby={
                                                                            refetchWaasLobby
                                                                        }
                                                                    />
                                                                </Flex>
                                                            )
                                                        )}
                                                        {Genesis.map(
                                                            (
                                                                character,
                                                                index
                                                            ) => (
                                                                <Flex
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <Card
                                                                        character={
                                                                            character
                                                                        }
                                                                        index={
                                                                            index +
                                                                            3
                                                                        }
                                                                        levels={
                                                                            "Genesis"
                                                                        }
                                                                        referCard={
                                                                            true
                                                                        }
                                                                        gameContract={
                                                                            gameContract
                                                                        }
                                                                        currentDay={
                                                                            currentDay
                                                                        }
                                                                        lastDayEntered={
                                                                            lastDayEntered
                                                                        }
                                                                        refetchAssets={
                                                                            refetchAssets
                                                                        }
                                                                        refetchLastDay={
                                                                            refetchLastDay
                                                                        }
                                                                        refetchGlobals={
                                                                            refetchGlobals
                                                                        }
                                                                        refetchDailyData={
                                                                            refetchDailyData
                                                                        }
                                                                        refetchWaasLobby={
                                                                            refetchWaasLobby
                                                                        }
                                                                    />
                                                                </Flex>
                                                            )
                                                        )}                                                        
                                                    </Flex>
                                                </LD_WP_Card1>
                                                {/*<LD_WP_Card2>
                                                    <Flex
                                                        flexDirection="row"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {Genesis.map(
                                                            (
                                                                character,
                                                                index
                                                            ) => (
                                                                <Flex
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <Card
                                                                        character={
                                                                            character
                                                                        }
                                                                        index={
                                                                            index +
                                                                            3
                                                                        }
                                                                        levels={
                                                                            "Genesis"
                                                                        }
                                                                        referCard={
                                                                            true
                                                                        }
                                                                        gameContract={
                                                                            gameContract
                                                                        }
                                                                        currentDay={
                                                                            currentDay
                                                                        }
                                                                        lastDayEntered={
                                                                            lastDayEntered
                                                                        }
                                                                        refetchAssets={
                                                                            refetchAssets
                                                                        }
                                                                        refetchLastDay={
                                                                            refetchLastDay
                                                                        }
                                                                        refetchGlobals={
                                                                            refetchGlobals
                                                                        }
                                                                        refetchDailyData={
                                                                            refetchDailyData
                                                                        }
                                                                        refetchWaasLobby={
                                                                            refetchWaasLobby
                                                                        }
                                                                    />
                                                                </Flex>
                                                            )
                                                        )}
                                                    </Flex>
                                                </LD_WP_Card2>*/}
                                            </LobbyDayWrapper>
                                        </LobbyDayOuterWrapper>
                                    </>
                                )}
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Link onClick={() => setLobbyShow(true)}>
                                <Text
                                    fontSize="16px"
                                    color="grey"
                                    mb="36px"
                                    bold
                                >
                                    SHOW Lobby
                                </Text>
                            </Link>
                        </>
                    )}
                    <AuctionEntryWrapper>
                        <Text fontSize="22px" color="#fff">
                            Auction Entries
                        </Text>
                        <Flex mt="10">
                            {
                                <MantineProvider
                                    theme={{
                                        colorScheme: "dark",
                                        colors: {
                                            dark: [
                                                "#d5d7e0",
                                                "#acaebf",
                                                "#8c8fa3",
                                                "#666980",
                                                "#4d4f66",
                                                "#34354a",
                                                "#221f1d",
                                                "#221f1d",
                                                "#0c0d21",
                                                "#01010a",
                                            ],
                                        },
                                        primaryColor: "dark", // Set primary color
                                    }}
                                >
                                    <ThemeProvider theme={theme}>
                                        <MantineReactTable
                                            mt="10"
                                            table={table}
                                        />
                                    </ThemeProvider>
                                </MantineProvider>
                            }
                        </Flex>
                    </AuctionEntryWrapper>
                    <ReactModal
                        isOpen={isSelectModalOpen}
                        onRequestClose={() => closeModal()}
                        style={customStyles}
                    >
                        <Flex flexDirection="column" className="accountModal">
                            <Flex
                                alignItems="center"
                                mb="20px"
                                justifyContent="space-between"
                            >
                                {_inDay === true ? (
                                    <>
                                        <Text
                                            fontSize="24px"
                                            ml="5px"
                                            mr="30px"
                                            color="white"
                                            bold
                                        >
                                            ADD PLS TO LOBBY
                                        </Text>
                                    </>
                                ) : (
                                    <>
                                        <Text
                                            fontSize="24px"
                                            ml="5px"
                                            mr="30px"
                                            color="white"
                                            bold
                                        >
                                            ENTER LOBBY
                                        </Text>
                                    </>
                                )}
                            </Flex>
                            <Flex
                                flexDirection="column" 
                                mb="20px"
                                justifyContent="space-between"
                            >
                                <label
                                    htmlFor="num-of-miners-input"
                                    style={{
                                        color: "#FF6E36",
                                        marginBottom: "16px",
                                    }}
                                >
                                    Amount (PLS)
                                </label>                                
                                <TextField
                                    defaultValue={Number(formInput.plsAmount)}
                                    value={parseFloat(formInput?.plsAmount) >= 1 ? Number(formInput?.plsAmount) : 0}
                                    variant="filled"
                                    type="number"
                                    sx={{ input: { color: "white" } }}
                                    InputLabelProps={{
                                        style: { color: "#ff4655" },
                                    }}
                                    className="inputField"
                                    onChange={(e) => {
                                        var value = e.target.value;
                                        if (value > 8000000000)
                                            value = 8000000000;
                                        if (value < 1) value = 1;
                                        updateFormInput({
                                            ...formInput,
                                            plsAmount: value,
                                        });
                                    }}
                                />
                            </Flex>
                            <Flex
                                flexDirection="column" 
                                mb="20px"
                                justifyContent="space-between"
                            >
                                <label
                                    htmlFor="num-of-miners-input"
                                    style={{
                                        color: "#FF6E36",
                                        marginBottom: "16px",
                                    }}
                                >
                                    Referrer ID
                                </label>                                
                                                          
                                <TextField
                                    disabled={_inDay}
                                    defaultValue={formInput.referrerID}
                                    value={formInput.referrerID}
                                    variant="filled"
                                    type="number"
                                    sx={{ input: { color: "white" } }}
                                    InputLabelProps={{
                                        style: { color: "#ff4655" },
                                    }}
                                    className="inputField"
                                    onChange={(e) => {
                                        var value = e.target.value;
                                        if (value < 1) value = 0;
                                        updateFormInput({
                                            ...formInput,
                                            referrerID: value,
                                        });
                                    }}
                                />
                            </Flex>
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Button
                                    primary
                                    className={`orange_btn btn_Duelists`}                                
                                    size="large"
                                    onClick={() => writeModal()}
                                    color="green"
                                    outline
                                    rounded
                                >
                                    Enter Lobby
                                </Button>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                size="medium"
                                                color="warning"
                                                onChange={switchHandler}
                                            />
                                        }
                                        label="Yield"
                                    />
                                </FormGroup>
                            </Flex>
                        </Flex>
                    </ReactModal>

                    
                </Flex>
            )}            	
		</div>

		<div class="section padding-top-bottom over-hide z-bigger background-dark-3 footer">
			<div class="shadow-on-footer" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.1s">Pulse Miner</div>
			<div class="container" data-scroll-reveal="enter bottom move 20px over 0.5s after 0.4s">
				<div class="row">
					<div class="col-12 text-center z-bigger py-5">
						<div class="footer-lines">
							<a href="project-1.html" class="hover-target animsition-link"><h4>Next: Mine PLS</h4></a>
						</div>
					</div>
				</div>
			</div>		
		</div>



</>

        </ScrollArea>
    );
};

const CountdownContainer = styled.section`
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 2px 14px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(65, 59, 55, 0.3);
    background-color: rgba(65, 59, 55, 0.3);
    display: flex;
    flex-direction: column;
    // max-width: 339px;
    padding: 24px 0 24px 24px;
`;

const Header = styled.header`
    display: flex;
    gap: 8px;
    color: #c2b7b1;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    padding-right: 80px;
`;

const EndsInText = styled.span`
    font-family: "Inter", sans-serif;
`;

const ClockIcon = styled.img`
    width: 16px;
    height: 16px;
    object-fit: auto;
    object-position: center;
    margin: auto 0;
`;

const TimeContainer = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 24px;
    padding-right: 80px;
    white-space: nowrap;
`;

const TimeUnit = styled.div`
    display: flex;
    flex-direction: column;
`;

const TimeValue = styled.span`
    border: 1px solid rgba(65, 59, 55, 1);
    border-radius: 6px;
    color: #f1ede9;
    text-align: right;
    padding: 10px;
    font: 600 16px "Inter", sans-serif;
`;

const TimeLabel = styled.span`
    color: rgba(199, 185, 178, 0.8);
    text-align: center;
    margin-top: 6px;
    font: 500 12px/150% "Inter", sans-serif;
`;

const DividerIcon = styled.img`
    width: 2px;
    aspect-ratio: 0.33;
    object-fit: auto;
    object-position: center;
    fill: #c2b7b1;
    align-self: start;
    margin-top: 15px;
`;
const DashInfoHeading = styled.div`
    padding-right: 160px;
    padding-left: 160px;
    @media screen and (max-width: 1250px) {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }
    @media screen and (max-width: 991px) {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }
    @media screen and (max-width: 600px) {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }
`;

const DashInfoWrapper = styled(Flex)`
    width: 100%;
    padding-right: 160px;
    padding-left: 160px;
    @media screen and (max-width: 1250px) {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }
    @media screen and (max-width: 991px) {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }
    @media screen and (max-width: 600px) {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }
    @media screen and (max-width: 991px) {
        display: flex;
        flex-direction: column;
    }
`;
const BoxLeft = styled.div`
    width: 75%;
    padding-right: 10%;
    @media screen and (max-width: 991px) {
        width: 100%;
        padding-right: 0;
        margin-top: 50px;
    }
`;
const BoxRight = styled.div`
    width: 25%;
    @media screen and (max-width: 991px) {
        width: 100%;
        max-width: 275px;
    }
`;
const HeadingInfo = styled.div`
    color: #c7b9b2;
    font-size: 14px;
`;
const ContentInfo = styled.div`
    font-size: 34px;
    margin-bottom: 5px;
`;
const InfoBox = styled.div`
    margin-bottom: 20px;
    margin-right: 30px;
`;
const InfoBoxWrapper = styled.div`
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid rgba(65, 59, 55, 0.4);
    &:last-child {
        border-bottom: 0;
    }
`;

const FiveDayBonusWrapper = styled.div`
    background: #141413;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-right: 160px;
    padding-left: 160px;
    @media screen and (max-width: 1250px) {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }
    @media screen and (max-width: 991px) {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }
    @media screen and (max-width: 600px) {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }
`;

const FD_Wrapper_Title = styled.h1`
    color: var(--Brand-Lighter, #ff6e36);
    letter-spacing: -0.72px;
    width: 100%;
    font: 600 36px/60px Inter, sans-serif;
    margin: 0;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const FD_Wrapper_Bonus = styled.span`
    font-weight: 300;
    color: rgba(255, 110, 54, 1);
`;

const FD_Wrapper_Section = styled.section`
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
    // gap: 20px;
    @media (max-width: 991px) {
        max-width: 100%;
        flex-wrap: wrap;
        margin-top: 40px;
    }
`;

const FD_Wrapper_Card = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    @media (max-width: 991px) {
        width: 50%;
        padding-right: 0;
        margin-top: 20px;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const FD_Wrapper_Stats = styled.div`
    display: flex;
    gap: 12px;
    font-size: 18px;
    color: var(--T---Main, #c2b7b1);
    font-weight: 700;
    line-height: 38px;
`;

const FD_Wrapper_StatLabel = styled.span`
    font-family: Inter, sans-serif;
    font-weight: 500;
`;

const FD_Wrapper_StatSeparator = styled.span`
    color: var(--T---Description, rgba(199, 185, 178, 0.8));
    font-family: Inter, sans-serif;
`;

const FD_Wrapper_StatValue = styled.span`
    font-family: Inter, sans-serif;
`;

const FD_Wrapper_Amount = styled.div`
    color: #fff;
    letter-spacing: -0.72px;
    margin-top: 24px;
    font: 600 36px/44px Inter, sans-serif;
`;

const FD_Wrapper_ImportantValue = styled.span`
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 1.2px;
`;

const LobbyDayOuterWrapper = styled.div`
    padding-top: 60px;
    padding-right: 160px;
    padding-left: 160px;
    @media screen and (max-width: 1250px) {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }
    @media screen and (max-width: 991px) {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }
    @media screen and (max-width: 600px) {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }
`;
const LobbyDayWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-flow: wrap;
`;
const LD_WP_Card1 = styled.div`
    margin-left: 30px;
    width: 30%;
    max-width: 300px;
    @media screen and (max-width: 1250px) {
        margin-left: 0px;
        margin-right: 30px;
    }
    @media screen and (max-width: 991px) {
        width: 50%;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        max-width: unset;
        margin-right: 0;
        margin-bottom: 30px;
    }
`;
const LD_WP_Card2 = styled.div`
    margin-left: 30px;
    width: 30%;
    max-width: 300px;
    @media screen and (max-width: 1250px) {
        margin-left: 0px;
    }
    @media screen and (max-width: 991px) {
        width: 50%;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        max-width: unset;
    }
`;
const LD_WP_AuctionLobbyContainer_Inner = styled.div`
    max-width: 363px;
`;

const LD_WP_AuctionLobbyContainer = styled.section`
    display: flex;
    width: 40%;
    flex-direction: column;
    @media screen and (max-width: 1250px) {
        width: 100%;
        margin-bottom: 30px;
    }
`;

const LD_WP_Title = styled.h1`
    color: var(--Brand-Lighter, #ff6e36);
    text-align: left;
    width: 100%;
    font: 600 36px/150% Inter, sans-serif;
`;

const LD_WP_LobbyInfo = styled.div`
    display: flex;
    margin-top: 16px;
    gap: 8px;
    align-items: center;
    padding: 0 20px;
`;

const LD_WP_LobbyDay = styled.h2`
    color: var(--Base, #f1ede9);
    letter-spacing: -0.88px;
    font: 600 44px/136% Inter, sans-serif;
    margin: 0;
`;

const LD_WP_LobbyCount = styled.span`
    leading-trim: both;
    text-edge: cap;
    justify-content: center;
    border-radius: 6px;
    background-color: var(--Brand-Fade-10, rgba(175, 71, 38, 0.2));
    color: var(--Brand-Lighter, #ff6e36);
    white-space: nowrap;
    letter-spacing: -0.84px;
    padding: 5px 18px;
    margin-left: 10px;
    font: 300 42px/143% Inter, sans-serif;
`;

const LD_WP_Description = styled.p`
    color: var(--T---Description, rgba(199, 185, 178, 0.8));
    margin-top: 16px;
    width: 100%;
    font: 400 14px/20px Inter, sans-serif;
`;

const LD_WP_Divider = styled.hr`
    border-color: rgba(65, 59, 55, 0.4);
    background-color: rgba(65, 59, 55, 0.4);
    margin-top: 34px;
    width: 100%;
`;

const LD_WP_StatusSection = styled.section`
    color: var(--T---Description, rgba(199, 185, 178, 0.8));
    margin-top: 33px;
    width: 100%;
    font: 500 14px/143% Inter, sans-serif;
`;

const LD_WP_StatusInfo = styled.div`
    border-radius: 8px;
    display: flex;
    margin-top: 16px;
    padding-right: 20px;
    gap: 8px;
`;

const LD_WP_StatusIconContainer = styled.div`
    justify-content: center;
    align-items: center;
    border-radius: 57px;
    background-color: rgba(33, 223, 134, 0.2);
    display: flex;
    width: 24px;
    height: 24px;
    padding: 8px;
`;

const LD_WP_Icon = styled.img`
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 14px;
`;

const LD_WP_StatusMessage = styled.p`
    color: var(--Succsess, #21bf75);
    margin: auto 0;
    font: 400 16px Inter, sans-serif;
`;
const AuctionEntryWrapper = styled.p`
    margin-top: 30px;
    padding-right: 160px;
    padding-left: 160px;
    @media screen and (max-width: 1250px) {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }
    @media screen and (max-width: 991px) {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }
    @media screen and (max-width: 600px) {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }
`;
const Button = styled.button`
    border-radius: 8px;
    padding: 10px 18px;
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: ${({ primary }) => (primary ? "#d9511c" : "#171615")};
    color: ${({ primary }) => (primary ? "#f1ede9" : "#d9511c")};
    border: ${({ secondary }) => secondary && "1px solid #d9511c"};
`;
export default SelectCharacter;
