import { Flex, Text } from "crox-new-uikit";
import React from "react";
import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsAvatar,
    useEnsName,
} from "wagmi";
import useMediaQuery from "use-mediaquery";
import ReactiveButton from "reactive-button";
import { Icon } from "@iconify/react";
import styled from "styled-components";
let isConfirm = false;
const WalletConnect = () => {
    const { address, connector, isConnected } = useAccount();
    const { data: ensAvatar } = useEnsAvatar({ addressOrName: address });
    const { data: ensName } = useEnsName({ address });
    const { connect, connectors, error, isLoading, pendingConnector } =
        useConnect();
    const { disconnect } = useDisconnect();

    const handleLogin = (conn) => {
        isConfirm = true;
        localStorage.setItem("accountStatus", "1");
        return connect(conn);
    };
    const isMobile = useMediaQuery("(max-width: 600px)");

    return (
        <ConnectWalletWrapper>
            <Flex
                flexDirection="column"
                alignItems="center"
                className="container-n connectwalletwrapperinner"
            >
                <Text fontSize="24px" mb={10} color="#e2e2e2" bold>
                    Connect Your Wallet!
                </Text>
                <Text color="#e2e2e2" mb={20}>
                    (Connect with Matic Test Network)
                </Text>
                {/* <lottie-player
                autoplay
                loop
                mode="normal"
                src="https://assets10.lottiefiles.com/packages/lf20_gftlvsbm.json"
                class='animation'
                style={{ width: '200px' }}
            /> */}

                {connectors.map((connector) => (
                    <Flex
                        m={isMobile ? "20px 10px" : "14px 20px"}
                        style={{ width: "100%" }}
                    >
                        <ReactiveButton
                            disabled={!connector.ready}
                            key={connector.id}
                            style={{ width: "100%" }}
                            idleText={
                                <Flex alignItems="center">
                                    <Icon
                                        icon="logos:metamask-icon"
                                        color="white"
                                        width="25"
                                        height="25"
                                    />
                                    <Text ml="5px" bold>
                                        {connector.name}
                                        {!connector.ready && " (unsupported)"}
                                        {isLoading &&
                                            connector.id ===
                                                pendingConnector?.id &&
                                            " (connecting)"}
                                    </Text>
                                </Flex>
                            }
                            size="large"
                            onClick={() => handleLogin({ connector })}
                            rounded
                        />
                    </Flex>
                ))}
                <Text color="#e2e2e2" mt={20}>
                    Very interesting things await you.
                </Text>
            </Flex>
        </ConnectWalletWrapper>
    );
};

const ConnectWalletWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.6);
    .connectwalletwrapperinner {
        width: 100%;
        max-width: 440px;
        .reactive-btn-wrapper {
            width: 100%;
            button {
                width: 100%;
                border-radius: 6px !important;
                // display: flex;
                // justify-content: center;
                padding: 14px 24px !important;
                box-shadow: none !important;
                background: rgba(255, 255, 255, 0.1) !important;
                svg {
                    margin-right: 10px;
                }
            }
        }
    }
`;
export default WalletConnect;
