// Import the FontAwesomeIcon component
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
{
    faCircleDown
} from "@fortawesome/free-solid-svg-icons";
import Typewriter from 'typewriter-effect';
import '../styles/inner.css';
import imgBG13 from "../../assets/img/trudefi/bg/13.jpg";
import imgREF4 from "../../assets/img/referrals/4.png";
import imgREF5 from "../../assets/img/referrals/5.png";
import imgREF6 from "../../assets/img/referrals/6.png";

import { Button, Overlay, AspectRatio } from '@mantine/core';

export default function Section3()
{
    return (
        <>
        
        <Flex justifyContent="center" alignItems="center" className="section  over-hide  z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG13 + ")", backgroundSize: "100%", backgroundRepeat: "no-repeat", padding: "15px"}}>
            <div className="text-center">
                <Flex mt="60px" justifyContent="center" flexDirection="column" alignItems="center" width="100px">
                    <Text fontSize="28px" color="#fff" bold mr="14px" >Tru2X is a TruDeFi.io development</Text>    
                </Flex>              

                <div class="container">
                    <div class="row px-5 px-xl-2 justify-content-center">
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-5 mb-5">
                            <div class="img-wrap">
                                <img src={imgREF4} alt=""/>
                            </div>	
                        </div>	

                    </div>	
    
                    <div class="row px-5 px-xl-2 justify-content-center">
                        <div class="col-sm-7 col-md-7 col-lg-7 px-xl-0 order-first order-xl-first">
                            <div class="img-wrap">
                                        <img src={imgREF5} alt=""/>
                            </div>	

                            <div class=" mt-5">
                                <div class="row " style={{ width: "100%" }}>
                                    <Flex class="text-xl-left" style={{ width: "100%" }} data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
                                        <Text style={{ color: "white", fontWeight: 600 }} class="mb-3">Tru2X not only offers an Activity-Inviting Referral Program but also takes Community Building to an Entirely New Level. We Focus on Building Profitable Incentives to Connect Efficiently with Partnering Platforms and for Benevolent Purposes.               </Text>

                                    </Flex>             
                                </div>
                            </div>
                        </div>	
                        <div class="col-sm-5 col-md-5 col-lg-5 mt-5 text-center text-xl-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.1s">
                            <div class="img-wrap">
                                        <img src={imgREF6} alt=""/>
                            </div>	
                        </div>	
                    </div>		
                </div>		
            </div>
        </Flex>
        </>
    )
}