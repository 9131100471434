// Import the FontAwesomeIcon component
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { Box, Divider, Grid, Col } from '@mantine/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
{
    faCircleDown
} from "@fortawesome/free-solid-svg-icons";
import Typewriter from 'typewriter-effect';
import '../styles/inner.css';
import imgBG13 from "../../assets/img/trudefi/bg/13.jpg";
import imgPNR2 from "../../assets/img/partners/2.jpg";
import imgPNR3 from "../../assets/img/partners/3.png";
import imgPNR4 from "../../assets/img/partners/4.png";

import imgPNR_L1 from "../../assets/img/partners/logos/1.png";
import imgPNR_L2 from "../../assets/img/partners/logos/2.png";
import imgPNR_L3 from "../../assets/img/partners/logos/3.png";
import imgPNR_L4 from "../../assets/img/partners/logos/4.png";
import imgPNR_L5 from "../../assets/img/partners/logos/5.png";
import imgPNR_L6 from "../../assets/img/partners/logos/1.png";

import styled from "styled-components";

import { Overlay, AspectRatio } from '@mantine/core';

export default function Section3()
{
    return (
        <>
        
        <Flex justifyContent="center" alignItems="center" className="section  over-hide  z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG13 + ")", backgroundSize: "100%", backgroundRepeat: "no-repeat", padding: "15px"}}>
            <div className="text-center">
          

                <div class="container">

    
                    <div class="row px-5 px-xl-2 justify-content-center">
                        <div class="col-sm-7 col-md-7 col-lg-7 px-xl-0 order-first order-xl-first">

                            <div class=" mb-5">
                                <Flex flexDirection="column" style={{ backgroundImage:"url(" + imgPNR2 + ")", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", padding: "15px"}}>
                                    <div class="row " style={{ width: "100%" }}>
                                        <Flex class="text-xl-left" style={{ width: "100%" }} data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
                                            <Text style={{ color: "white", fontWeight: 600 }} class="mb-3">Tru2X not only offers an Activity-Inviting Referral Program but also takes Community Building to an Entirely New Level. We Focus on Building Profitable Incentives to Connect Efficiently with Partnering Platforms and for Benevolent Purposes.               </Text>
                                        </Flex>             
                                    </div>
                                </Flex>
                            </div>
                            <Flex flexDirection="column" style={{ backgroundImage:"url(" + imgPNR3 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>

                            <Flex m="25px" style={{ height: '200px' }} flexDirection="column" alignItems="flex-start">

                            </Flex>
                            <Flex style={{ height: '200px' }}  justifyContent="center" alignItems="flex-end">
                            <Grid mt="50px" gutter={10}>
<Col span={6} md={3} lg={3} >
    <div class="img-wrap">
        <img src={imgPNR_L1} alt=""/>
    </div>	
</Col>
<Col span={6} md={3} lg={3} >
<div class="img-wrap">
        <img src={imgPNR_L2} alt=""/>
    </div>
</Col>
<Col span={6} md={3} lg={3} >
<div class="img-wrap">
        <img src={imgPNR_L3} alt=""/>
    </div>
</Col>
<Col span={6} md={3} lg={3} >
<div class="img-wrap">
        <img src={imgPNR_L4} alt=""/>
    </div>
</Col>
<Col span={6} md={3} lg={3} >
<div class="img-wrap">
        <img src={imgPNR_L5} alt=""/>
    </div>
</Col>
<Col span={6} md={3} lg={3} >
<div class="img-wrap">
        <img src={imgPNR_L6} alt=""/>
    </div>
</Col>
</Grid> 
                            </Flex>
                        </Flex>



                        </div>	
                        <div class="col-sm-5 col-md-5 col-lg-5 text-center text-xl-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.1s">
                        <Flex flexDirection="column" style={{ backgroundImage:"url(" + imgPNR4 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                            <Flex style={{ height: '100px' }} flexDirection="column" alignItems="flex-start">
                            <Text fontSize="24px" style={{ color: "white", fontWeight: 600 }} class="mb-3">Active Affiliate Partnership Proposal: TruDeFi | Tru2X assist you improving your Stellar Project                            </Text>
                            </Flex>
                            <Flex m="25px" style={{ height: '200px' }} flexDirection="column" alignItems="flex-start">
                            <p style={{ color: "black", fontWeight: 600 }} class="mb-0 pb-0">Key Highlights</p>	
                            <ul className="list-disc" style={{ color: "black", fontSize: "16px"  }}>
                                <li>Significantly Increased TVL</li>
                                <li><p  class="mb-0 pb-0">TruDeFi Market Buys Your Token                                </p>	</li>
                                <li><p  class="mb-0 pb-0">Tailored for Founders & Community Leaders/Influencers </p>	</li>
                                <li><p  class="mb-0 pb-0">Continuous Incentives for Participants to Hold Your Token                                </p>	</li>
                                <li><p  class="mb-0 pb-0">No Costs or Financial Effort for TruDeFi Partners                                </p>	</li>
                                <li><p  class="mb-0 pb-0">Robust Safety and Backup Tools                                </p>	</li>
                                <li><p  class="mb-0 pb-0">No Technical Requirements                                </p>	</li>
                            </ul>                            
                            </Flex>
                            <Flex style={{ height: '200px' }}  justifyContent="center" alignItems="flex-end">
                            <Button primary className={`orange_btn btn_1`} size="large" outline rounded>
                                         Compound Your Dividends
                                    </Button>
                            </Flex>
                        </Flex>
                        </div>	
                    </div>		
                </div>		
            </div>
        </Flex>
        </>
    )
}
const Button = styled.button`
    border-radius: 8px;
    padding: 10px 18px;
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: ${({ primary }) => (primary ? "#d9511c" : "#171615")};
    color: ${({ primary }) => (primary ? "#f1ede9" : "#d9511c")};
    border: ${({ secondary }) => secondary && "1px solid #d9511c"};
`;