// Import the FontAwesomeIcon component
import Typewriter from 'typewriter-effect';
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import imgBG23 from "../../assets/img/trudefi/bg/23.jpg";
import imgTD4 from "../../assets/img/trudefi/4.png";


export default function Section6()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG23 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">
	
<div class="container">
<div class="row px-5 px-xl-2 justify-content-center">
  <div class="col-md-9 mt-5">
    <div class="img-wrap">
      <img src={imgTD4} alt=""/>
    </div>	
  </div>	
  <div class="col-md-3 mt-5">
    <div class="row mt-5 mt-xl-0 justify-content-center">
        <div class="offset-xl-1 col-sm-8 col-md-6 col-lg-12 text-center text-xl-left" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
          <h5 style={{ color: "black", fontWeight: 600 }} class="mb-3">Because: <br/> “Behind every Transaction <br/> is a Dream” </h5>

        </div>             
      </div>
  </div>
</div>		
</div>		
                </div>
            </Flex>
        </>
    )
}