// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import
{
    faCircleDown
} from "@fortawesome/free-solid-svg-icons";
import Typewriter from 'typewriter-effect';
import imgBG21 from "../../assets/img/trudefi/bg/21.jpg";
import imgHOW4 from "../../assets/img/howto/4.png";
// Homepage Section2 Section
export default function Section5()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG21 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">
                    <div className="box-cont h-fit w-fit px-14 mb-10 py-8 shadow-md bg-gradient-to-r from-neutral-900 rounded-lg">
                        <h2 className="text-white font-bold">👨‍🚀 Sodales fermentum</h2>
                        <h4 className="lead text-white font-bold">
                            <Typewriter
                                options={{
                                    strings: ["ut.", "varius.", "porta."],
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </h4>
                        <div class="container">
<div class="column px-5 px-xl-2 justify-content-center">
          <Flex mt="60px" justifyContent="center" flexDirection="column" alignItems="left" width="100px">
            <Text style={{ color: "black", fontWeight: 600 }} fontSize="28px" color="#fff" bold mr="14px" >Compounding: Let’s Cut to the Chase
            </Text>    
          </Flex>              
            
      </div>  
      <div class="col-12 mt-5" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.1s">
				<div class="img-wrap">
					<img src={imgHOW4} alt=""/>
				</div>	
			</div>	

		</div>  
                        <a href="/whitepaper/whitepaper.pdf"
                            target="_blank"
                            className="bg-slate-300 text-black hover:bg-red-600 active:bg-red-900 font-bold uppercase text-base px-8 py-3 rounded-[24px] shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                            <span>tincidunt <FontAwesomeIcon icon={faCircleDown} className="ml-2" /></span>
                        </a>
                    </div>
                </div>
                <div></div>
            </Flex>
        </>
    )
}