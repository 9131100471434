// Import the FontAwesomeIcon component
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
{
    faTelegram
} from "@fortawesome/free-brands-svg-icons";
import '../styles/inner.css';
import imgBG12 from "../../assets/img/trudefi/bg/12.jpg";


import img1 from "../../assets/img/1.jpg";
import img2 from "../../assets/img/2.jpg";
import img3 from "../../assets/img/3.jpg";
import img4 from "../../assets/img/4.jpg";
import img5 from "../../assets/img/5.jpg";
import img6 from "../../assets/img/6.jpg";
import img7 from "../../assets/img/7.jpg";
import img8 from "../../assets/img/8.jpg";
import img10 from "../../assets/img/10.jpg";
import img11 from "../../assets/img/11.png";
import img12 from "../../assets/img/12.png";
import logo from "../../assets/img/logo.png";
import vid1 from "../../assets/img/videos/Media1.mp4";
import vid2 from "../../assets/img/videos/Media2.mp4";
import vid3 from "../../assets/img/videos/Media3.mp4";

import img2X5 from "../../assets/img/tru2x/5.png";
import img2X6 from "../../assets/img/tru2x/6.png";
import img2X7 from "../../assets/img/tru2x/7.png";
import img2X1 from "../../assets/img/tru2x/1.jpg";
import img2X10 from "../../assets/img/tru2x/10.jpg";
import img2X11 from "../../assets/img/tru2x/11.jpg";
import img2X12 from "../../assets/img/tru2x/12.jpg";
import img2X13 from "../../assets/img/tru2x/13.jpg";
// Homepage Section2 Section
export default function Section2()
{
    return (
        <>
            {/* PARALLAX TWO START */}
            <Flex className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG12 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
            <div class="container">

      <div class="column px-5 px-xl-2 justify-content-center">
          <Flex mt="60px" justifyContent="center" flexDirection="column" alignItems="center" width="100px">
            <Text fontSize="28px" color="#fff" bold mr="14px" >Tru2X is a TruDeFi.io development</Text>    
          </Flex>              
          <Flex mt="1px" mb="50px" justifyContent="center" flexDirection="column" alignItems="center" width="100px">
            <Text fontSize="20px" color="#fff" bold mr="14px" >and it serves every cryptocurrency holder to double their token count. Repeatedly, if they like.</Text>    
          </Flex>             
      </div>
		</div>  


		<div class="section padding-bottom-big over-hide z-bigger">
			<div class="container-fluid">
				<div class="row px-5 px-xl-2 px-xl-0">
					<div class="col-xl-6 px-xl-0 align-self-center">
						<div class="row mt-5 mt-xl-0 justify-content-center">
							<div class="offset-xl-5 col-sm-8 col-md-6 col-lg-5 text-center text-xl-right" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
								<h5 class="mb-3">Currency loyal</h5>
								<p class="mb-0 pb-0">Deposit without swapping to a platform token. Tru2X is a Service Tool for your Crypto, not a currency</p>	
							</div>
							<div class="offset-xl-5 col-sm-8 col-md-6 col-lg-5 mt-5 mt-md-0 mt-xl-5 text-center text-xl-right" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
								<h5 class="mb-3">Yield security</h5>
								<p class="mb-0 pb-0">User Dividends are backed by externally generated yield and revenues from services & infrastructure providers within the Crypto industry. (*DeFi)</p>
							</div>
							<div class="offset-xl-5 col-sm-8 col-md-6 col-lg-5 mt-5 text-center text-xl-right" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
								<h5 class="mb-3">User-Approved and Founder-Endorsed</h5>
								<p class="mb-0 pb-0">Trusted by users and endorsed by platform pioneers, our service combines community confidence with expert validation. Join a platform where credibility meets innovation</p>
							</div>
						</div>
					</div>
					<div class="col-xl-6 px-xl-0 order-first order-xl-last">
            <div class="video-section hover-target">
							<figure> 
                <iframe width="700" height="400" src={vid1} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</figure>
						</div>	
					</div>	
				</div>		
			</div>	
		</div>

                </Flex>
            {/* PARALLAX TWO END */}
        </>
    )
}