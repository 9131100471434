// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import
{
    faTelegram
} from "@fortawesome/free-brands-svg-icons";
import imgBG24 from "../../assets/img/trudefi/bg/24.jpg";
import imgHOW3 from "../../assets/img/howto/3.png";
// Homepage Section2 Section
export default function Section6()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG24 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">
                    <div className="box-cont h-fit w-fit mt-[10%] px-14 mb-10 py-8 shadow-md bg-gradient-to-r from-neutral-900 rounded-lg">
                    <div class="container-fluid">
				<div class="row px-5 px-xl-2 px-xl-0">
					<div class="col-xl-5 px-xl-0 align-self-center">
						<div class="row mt-5 mt-xl-0 justify-content-center">
              

							<div class="offset-xl-2 col-sm-8 col-md-4 col-lg-5 text-left text-xl-left" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
								<h5 style={{ color: "black", fontWeight: 600 }} class="mb-3">The Path Ahead: Shaping a Future Rooted in Integrity and Innovation. Our roadmap is a commitment to transparency, responsibility, and the unwavering pursuit of a world where technology empowers and uplifts all.
                </h5>
								<p class="mb-0 pb-0"></p>	
							</div>


						</div>
					</div>
					<div class="col-xl-6 px-xl-0 order-first order-xl-last justify-content-left">
              <div class="col-md-12 mt-5">
                <div class="img-wrap">
                  <img src={imgHOW3} alt=""/>
                </div>	
              </div>	
        
					</div>	
				</div>		
			</div>		
                        <a href="https://t.me/R3D4NG3L"
                            target="_blank"
                            className="bg-slate-300 mt-5 text-black hover:bg-red-600 active:bg-red-900 font-bold uppercase text-base px-8 py-3 rounded-[24px] shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                            <span>Suspendisse <FontAwesomeIcon icon={faTelegram} className="ml-2" /></span>
                        </a>
                    </div>
                </div>
            </Flex>
        </>
    )
}