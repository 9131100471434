import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Link } from "crox-new-uikit";
import useMediaQuery from "use-mediaquery";
import "../assets/style/scss/react-pro-sidebar.scss";
import "react-pro-sidebar/dist/css/styles.css";
import styled from "styled-components";
import ConnecButton from "./ConnecButton";

function HeaderSite(props) {
    const { setCollapse, collapse, toggle, setToggle } = props;
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width: 991px)");
    const [menuOpen, setMenuOpen] = useState(false);

    collapse && !isMobile
        ? (document.body.style.overflow = "hidden")
        : (document.body.style.overflow = "auto");

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <Header>
                <Logo src="/images/logo.png" alt="Logo" />
                {isMobile ? (
                    <HamburgerIcon onClick={handleMenuToggle}>
                        {menuOpen ? (
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="5.63604"
                                    y="4.22183"
                                    width="20"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 5.63604 4.22183)"
                                    fill="white"
                                />
                                <rect
                                    x="4.22183"
                                    y="18.364"
                                    width="20"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 4.22183 18.364)"
                                    fill="white"
                                />
                            </svg>
                        ) : (
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="32" height="4" fill="white" />
                                <rect
                                    y="14"
                                    width="32"
                                    height="4"
                                    fill="white"
                                />
                                <rect
                                    y="28"
                                    width="32"
                                    height="4"
                                    fill="white"
                                />
                            </svg>
                        )}
                    </HamburgerIcon>
                ) : (
                    <Nav>
                        <MenuItem onClick={() => navigate("/")}>Home</MenuItem>
                        {/*<MenuItem onClick={() => navigate("/")}>
                            The Ecosystem
                        </MenuItem>*/}
                        <MenuItem onClick={() => navigate("/lobby")}>
                            P2X Auction House
                        </MenuItem>
                        <MenuItem onClick={() => navigate("/nftch")}>
                            Launch Pulse Miner
                        </MenuItem>
                        <MenuItem>
                            <ConnecButton />
                        </MenuItem>
                    </Nav>
                )}
            </Header>
            {isMobile && menuOpen && (
                <MobileNav>
                    <MenuItem onClick={() => navigate("/")}>Home</MenuItem>
                    {/*<MenuItem onClick={() => navigate("/")}>
                        The Ecosystem
                    </MenuItem>*/}
                    <MenuItem onClick={() => navigate("/lobby")}>
                        P2X Auction House
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/nftch")}>
                        Launch Pulse Miner
                    </MenuItem>
                    <MenuItem>
                        <ConnecButton />
                    </MenuItem>
                </MobileNav>
            )}
        </>
    );
}

const Header = styled.header`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 26px;
    box-sizing: border-box;
    padding-right: 160px;
    padding-left: 160px;
    @media screen and (max-width: 1250px) {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }
    @media screen and (max-width: 991px) {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }
    @media screen and (max-width: 600px) {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }

    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
`;

const Logo = styled.img`
    width: 84px;
    aspect-ratio: 1.49;
    object-fit: cover;
`;

const HamburgerIcon = styled.div`
    cursor: pointer;
`;

const Nav = styled.nav`
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: space-between;
    font: 300 26px/143% Inter, sans-serif;
    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
`;

const MenuItem = styled.div`
    color: #fff;
    cursor: pointer;

    &:hover {
        
        color: #FF6E36;
    }
`;
const MobileNav = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background-color: #2b2725;
    z-index: 99;
    padding: 20px;
    position: absolute;
    top: 100px; // Adjust according to the height of your header
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    ${MenuItem} {
        margin-bottom: 20px;
    }
`;

const Button = styled.button`
    border-radius: 8px;
    padding: 10px 18px;
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: ${({ primary }) => (primary ? "#d9511c" : "#171615")};
    color: ${({ primary }) => (primary ? "#f1ede9" : "#d9511c")};
    border: ${({ secondary }) => secondary && "1px solid #d9511c"};
    height: auto;
`;

export default HeaderSite;
