import React, { Suspense, useState, useRef, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers, BigNumber } from "ethers";
import { WalletConnect } from "../../../components/Gadget";
import { fenixContract } from "../../../libraries/fenixContract";
import { helperContract } from "../../../libraries/helperContract";
import { minerContract } from "../../../libraries/minerContract";
import { NFTGAME_CONTRACT_ADDRESS, transformCharacterData } from "../../../utils/constants";
import {
    Chain,
    Address,
    useContractWrite,
    useNetwork,
    usePrepareContractWrite,
    useWaitForTransaction,
    useFeeData,
    useBalance,
    useAccount,
    useContractReads,
  } from "wagmi";
import SelectCharacter from "./SelectCharacter";
//import Init from '../../../components/Gadget/Init'
import myEpicGame from '../../../utils/MyEpicGame.json';
//import InitNFTs from "../../InitNFTs";

import awesomeGame from '../../../utils/StakingToken.json';
import './selectcharacter.scss'
import FENIX_ABI from "../../../models/abi/StakingToken.json"; 
import HELPER_ABI from "../../../models/abi/HelperToken.json"; 
import MINER_ABI from "../../../models/abi/MinerToken.json"; 

////////////////////////////////////////////////////////////////////////


import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
//// import './assets/style/css/globals.css';
import { Flex } from "crox-new-uikit";
import useMediaQuery from "use-mediaquery";
//import "./assets/js/custom";
/////////////////////////////////////////////////////////////////////
import Swiper from 'swiper';
import 'animsition/dist/css/animsition.min.css';
import 'animsition/dist/js/animsition.min.js';
import $ from 'jquery';


//import "./App.css";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/font-awesome.min.css";
//import "./assets/css/animsition.min.css";
import "../../../assets/css/swiper.min.css";
import "../../../assets/css/style.css";

import img1 from "../../../assets/img/project/1.jpg";
import img2 from "../../../assets/img/project/2.jpg";
import img3 from "../../../assets/img/project/3.jpg";
import img4 from "../../../assets/img/project/4.jpg";
import img5 from "../../../assets/img/project/5.jpg";
import logo from "../../../assets/img/logo.png";

//////////////////////////////////////////////////////////////







function _Libby(props) {

  const { collapse } = props
  const [characterNFT, setCharacterNFT] = useState([]);
  const [bonusWallets, setBonusWallets] = useState([]);
  const [lastDayEntered, setLastDayEntered] = useState([]);
  const [currentDay, setCurrentDay] = useState(0);
  const [enterTypes, setEnterTypes] = useState([1, 4, 5]);

  //const router = useRouter();
  const { chain } = useNetwork();
  const { address } = useAccount();
  const { data: feeData } = useFeeData({ formatUnits: "gwei", watch: false, cacheTime: 60_000 });
  const { data: fenixBalance } = useBalance({
    address: address,
    //token: fenixContract(chain).address,
    staleTime: 20_000,
  });

  //console.log(fenixContract(chain));
  var { data: txn, refetch:refetchAssets } = useContractReads({
      contracts: [
        {
          ...fenixContract(chain),
          functionName: "fetchAssets",
          args: [address, 0, 3, 1]
        },             
        {
          ...fenixContract(chain),
          functionName: "currentDay",
        },  
        {
          ...fenixContract(chain),
          functionName: "fetchAssets",
          args: ["0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266", 0, 3, 0]
        },                                                                    
      ],
      cacheTime: 300000,
      watch: false,
    });


    var { data: _lastDayEntered, refetch:refetchLastDay } = useContractReads({
      contracts: enterTypes?.map((_type) =>    
      ({
            ...fenixContract(chain),
            functionName: "lastDayEntered",
            args: [_type, address],     
        })
      ),
      cacheTime: 300000,
      watch: false,
    });

    /////////////////////////////////////////////////////////

    const [scrollPos, setScrollPos] = useState(0);
    const cursor1 = useRef(null);
    const cursor2 = useRef(null);
    const cursor3 = useRef(null);
    ///////////////////////////////////////////////////////////////////



    const isMobile = useMediaQuery("(max-width: 600px)");
    ////////////////////////////////////////////////////////////////

    useEffect(() => {

      const handleMouseMove = (e) => {
        if (cursor1.current && cursor2.current && cursor3.current) {
            const { clientX: x, clientY: y } = e;
            cursor1.current.style.left = `${x}px`;
            cursor1.current.style.top = `${y}px`;
            cursor2.current.style.left = `${x}px`;
            cursor2.current.style.top = `${y}px`;
            cursor3.current.style.left = `${x}px`;
            cursor3.current.style.top = `${y}px`;
        }
      };
  
      const handleMouseOver = () => {
        if (cursor2.current && cursor3.current) {
            cursor2.current.classList.add('hover');
            cursor3.current.classList.add('hover');
        }
      };
  
      const handleMouseOut = () => {
        if (cursor2.current && cursor3.current) {
            cursor2.current.classList.remove('hover');
            cursor3.current.classList.remove('hover');
        }
      };
  
      document.body.addEventListener('mousemove', handleMouseMove);
      const hoverTargets = document.querySelectorAll('.hover-target');
      hoverTargets.forEach((target) => {
        target.addEventListener('mouseover', handleMouseOver);
        target.addEventListener('mouseout', handleMouseOut);
      });
  
      return () => {
        document.body.removeEventListener('mousemove', handleMouseMove);
        hoverTargets.forEach((target) => {
          target.removeEventListener('mouseover', handleMouseOver);
          target.removeEventListener('mouseout', handleMouseOut);
        });
      };
    }, []);
  
    useEffect(() => {
      const scrollHandler = () => {
        const scrollPos = window.scrollY;
        setScrollPos(scrollPos);
      };
  
      window.addEventListener('scroll', scrollHandler);
      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    }, []);
  


    
    useEffect(() => {
    $('.animsition').animsition({
        inClass: 'fade-in',
        outClass: 'fade-out',
        inDuration: 1500,
        outDuration: 800,
        linkElement: 'a',
        loading: true,
        loadingParentElement: 'body', 
        loadingClass: 'animsition-loading',
        loadingInner: '', 
        timeout: false,
        timeoutCountdown: 5000,
        onLoadEvent: true,
        browser: [ 'animation-duration', '-webkit-animation-duration'],
        overlay : false,
        overlayClass : 'animsition-overlay-slide',
        overlayParentElement : 'body',
        transition: function(url){ window.location.href = url; }
      });
    }, []);




    useEffect(() => {
        // Page cursors
        const handleMouseMove = (n) => {
          if (t && e && i) {
            t.style.left = n.clientX + "px";
            t.style.top = n.clientY + "px";
            e.style.left = n.clientX + "px";
            e.style.top = n.clientY + "px";
            i.style.left = n.clientX + "px";
            i.style.top = n.clientY + "px";
          }
        };
    
        const t = document.getElementById('cursor');
        const e = document.getElementById('cursor2');
        const i = document.getElementById('cursor3');
    
        const addHover = () => {
          e.classList.add('hover');
          i.classList.add('hover');
        };
    
        const removeHover = () => {
          e.classList.remove('hover');
          i.classList.remove('hover');
        };
    
        document.body.addEventListener('mousemove', handleMouseMove);
    
        const hoverTargets = document.querySelectorAll('.hover-target');
        hoverTargets.forEach((target) => {
          target.addEventListener('mouseover', addHover);
          target.addEventListener('mouseout', removeHover);
        });
    
        // Parallax & fade on scroll
        const scrollBanner = () => {
          const handleScroll = () => {
            const scrollPos = window.scrollY;
            const parallaxFadeTop = document.querySelector('.parallax-fade-top');
            const parallaxTopShadow = document.querySelector('.parallax-top-shadow');
            const parallaxTop = document.querySelector('.parallax-top');
    
            if (parallaxFadeTop) {
              parallaxFadeTop.style.cssText = `top: ${scrollPos / 2}px; opacity: ${1 - scrollPos / 300};`;
            }
    
            if (parallaxTopShadow) {
              parallaxTopShadow.style.top = `${scrollPos / -2}px`;
            }
    
            if (parallaxTop) {
              parallaxTop.style.top = `${scrollPos / 2.2}px`;
            }
          };
    
          document.addEventListener('scroll', handleScroll);
        };
    
        scrollBanner();
    
        // Swiper initialization
        const swiper = new Swiper('.swiper-container', {
          scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
            dragSize: '19',
          },
          slidesPerView: 'auto',
          resistance: true,
          resistanceRatio: 0,
          speed: 800,
          autoplay: false,
          mousewheel: true,
          freeMode: true,
          grabCursor: true,
          touchStartPreventDefault: false,
          breakpoints: {
            1200: {
              freeMode: false,
            },
          },
        });
    
        // Hero slider mouse events
        const heroSlider = document.getElementById('hero-slider');
        if (heroSlider) {
          heroSlider.addEventListener('mousedown', () => document.body.classList.add('scale-up'));
          heroSlider.addEventListener('mouseup', () => document.body.classList.remove('scale-up'));
        }
    
        // Navigation
        const initNavigation = () => {
          const body = document.querySelector('body');
          const menu = document.querySelector('.menu-icon');
    
          const toggleClass = (element, stringClass) => {
            if (element.classList.contains(stringClass)) {
              element.classList.remove(stringClass);
            } else {
              element.classList.add(stringClass);
            }
          };
    
          if (menu) {
            menu.addEventListener('click', () => toggleClass(body, 'nav-active'));
          }
        };
    
        initNavigation();
    
        // Scroll back to top
        const scrollToTop = () => {
          const offset = 300;
          const duration = 400;
    
          const handleScroll = () => {
            const scrollTopButton = document.querySelector('.scroll-to-top');
            if (scrollTopButton) {
              if (window.scrollY > offset) {
                scrollTopButton.classList.add('active-arrow');
              } else {
                scrollTopButton.classList.remove('active-arrow');
              }
            }
          };
    
          const handleClick = (event) => {
            event.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          };
    
          window.addEventListener('scroll', handleScroll);
    
          const scrollTopButton = document.querySelector('.scroll-to-top');
          if (scrollTopButton) {
            scrollTopButton.addEventListener('click', handleClick);
          }
        };
    
        scrollToTop();
    
        // Hero Case Study Hover Effect
        const caseStudyHoverEffect = () => {
          const handleMouseEnter = (index) => {
            const activeName = document.querySelector('.case-study-name.active');
            const showImage = document.querySelector('.case-study-images li.show');
            const newImage = document.querySelector(`.case-study-images li:nth-child(${index})`);
            const newName = document.querySelector(`.case-study-name:nth-child(${index})`);
    
            if (activeName) activeName.classList.remove('active');
            if (showImage) showImage.classList.remove('show');
            if (newImage) newImage.classList.add('show');
            if (newName) newName.classList.add('active');
          };
    
          for (let i = 1; i <= 10; i++) {
            const caseStudyName = document.querySelector(`.case-study-name:nth-child(${i})`);
            if (caseStudyName) {
              caseStudyName.addEventListener('mouseenter', () => handleMouseEnter(i));
            }
          }
    
          const firstCaseStudyName = document.querySelector('.case-study-name:nth-child(1)');
          if (firstCaseStudyName) {
            firstCaseStudyName.dispatchEvent(new Event('mouseenter'));
          }
        };
    
        caseStudyHoverEffect();
    
        // Clean up event listeners on component unmount
        return () => {
          document.body.removeEventListener('mousemove', handleMouseMove);
          hoverTargets.forEach((target) => {
            target.removeEventListener('mouseover', addHover);
            target.removeEventListener('mouseout', removeHover);
          });
        };
      }, []);




    const [currentIndex, setCurrentIndex] = useState(0);
    const slides = [
      { id: 1, content: "Slide 1" },
      { id: 2, content: "Slide 2" },
      { id: 3, content: "Slide 3" }
    ];
  
    const nextSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    };
  
    const prevSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? slides.length - 1 : prevIndex - 1
      );
    };
  
    return (


        
            
            


<>


<div class="shadow-title parallax-top-shadow">The Battle</div>
		
		<div class="section padding-page-top padding-bottom over-hide z-bigger">
			<div class="container z-bigger">
				<div class="row page-title px-5 px-xl-2">
					<div class="col-12 parallax-fade-top">
						<h1>The Battle</h1>
					</div>
					<div class="offset-1 col-11 parallax-fade-top mt-2 mt-sm-3">
						<p>fashion, photography</p>
					</div>
				</div>
			</div>
		</div>

		<div class="section padding-bottom-big over-hide z-bigger">
			<div class="container">
				<div class="row px-5 px-xl-2 justify-content-center">
					<div class="col-12 mb-5">
						<div class="img-wrap">
							<img src={img1} alt=""/>
						</div>	
					</div>	
					<div class="col-lg-8 mb-5 text-center">
						<p class="mb-0 pb-0 lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni.</p>
					</div>
				</div>		
			</div>	
			<div class="container-fluid">
				<div class="row px-5 px-xl-2 px-xl-0">
					<div class="col-xl-6 px-xl-0 align-self-center">
						<div class="row mt-5 mt-xl-0 justify-content-center">
							<div class="offset-xl-5 col-sm-8 col-md-6 col-lg-5 text-center text-xl-right" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
								<h5 class="mb-3">art direction</h5>
								<p class="mb-0 pb-0">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.</p>	
							</div>
							<div class="offset-xl-5 col-sm-8 col-md-6 col-lg-5 mt-5 mt-md-0 mt-xl-5 text-center text-xl-right" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
								<h5 class="mb-3">photography</h5>
								<p class="mb-0 pb-0">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.</p>
							</div>
							<div class="offset-xl-5 col-sm-8 col-md-6 col-lg-5 mt-5 text-center text-xl-right" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
								<h5 class="mb-3">cinematography</h5>
								<p class="mb-0 pb-0">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.</p>
							</div>
							<div class="offset-xl-5 col-sm-8 col-md-6 col-lg-5 mt-5 text-center text-xl-right" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
								<h5 class="mb-3">advertising</h5>
								<p class="mb-0 pb-0">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.</p>
							</div>
						</div>
					</div>
					<div class="col-xl-6 px-xl-0 order-first order-xl-last">
						<div class="img-wrap">
							<img src={img2} alt=""/>
						</div>	
					</div>	
				</div>		
			</div>	
			<div class="container">
				<div class="row px-5 px-xl-2 justify-content-center">
					<div class="col-md-6 mt-5">
						<div class="img-wrap">
							<img src={img4} alt=""/>
						</div>	
					</div>	
					<div class="col-md-6 mt-5">
						<div class="img-wrap">
							<img src={img5} alt=""/>
						</div>	
					</div>
					<div class="col-lg-8 mt-5 text-center" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.1s">
						<p class="mb-0 pb-0 lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni.</p>
					</div>
					<div class="col-12 mt-5" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.1s">
						<div class="img-wrap">
							<img src={img3} alt=""/>
						</div>	
					</div>	
				</div>		
			</div>			
		</div>

		<div class="section padding-top-bottom over-hide z-bigger background-dark-3 footer">
			<div class="shadow-on-footer" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.1s">L'Etoile Feb</div>
			<div class="container" data-scroll-reveal="enter bottom move 20px over 0.5s after 0.4s">
				<div class="row">
					<div class="col-12 text-center z-bigger py-5">
						<div class="footer-lines">
							<a href="project-1.html" class="hover-target animsition-link"><h4>Next Project</h4></a>
						</div>
					</div>
				</div>
			</div>		
		</div>

</>







    );
}



export default _Libby;
