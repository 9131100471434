// Import the FontAwesomeIcon component
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
{
    faCircleDown
} from "@fortawesome/free-solid-svg-icons";
import Typewriter from 'typewriter-effect';
import '../styles/inner.css';
import imgBG13 from "../../assets/img/trudefi/bg/13.jpg";
import imgHOW10 from "../../assets/img/howto/10.png";
import vid1 from "../../assets/img/videos/Media1.mp4";

import { Button, Overlay, AspectRatio } from '@mantine/core';

export default function Section3()
{
    return (
        <>
        
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG13 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">
                    <div  className="justify-content-center">
                    <Overlay m="20%" style={{ borderRadius: '1rem', padding: "150px"}} zIndex={-1}
            gradient="linear-gradient(145deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)"
            opacity={0.85}
            rounded
          />
                        <h2 className="text-white font-bold">🌍 Rhoncus odio</h2>
                        <div class="container">


      
<div class="col px-4 px-xl-2 mt-5 justify-content-center">

<div class="col-md-5 mb-5 text-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">
        <div class="offset-xl-1  text-center text-xl-left" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
          <h5 style={{ color: "black", fontWeight: 600 }} class="mb-3">A Challenge mastered          </h5>
          <p style={{ color: "black", fontWeight: 600 }} class="mb-0 pb-0">There is no Token to buy to provide your 2X.
            <br/>PLS Deposit
            <br/>Withdraw PLS
          </p>	
        </div> 
    
  </div>

  <div class="row px-4 px-xl-2 mt-5 justify-content-center">
  <div class="col-md-5 mb-5 text-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">
        <div class="img-wrap">
			<img src={imgHOW10} alt=""/>
	    </div>	      
  </div>

  <div class="col-md-7 mb-5 text-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">
  <div class="video-section hover-target">
							<figure> 
                <iframe width="700" height="400" src={vid1} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</figure>
						</div>	
  </div>
</div>	
</div>		
</div>
                        <a href="/whitepaper/whitepaper.pdf"
                            target="_blank"
                            className="bg-slate-300 text-black hover:bg-red-600 active:bg-red-900 font-bold uppercase text-base px-8 py-3 rounded-[24px] shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                            <span>primis in faucibus <FontAwesomeIcon icon={faCircleDown} className="ml-2" /></span>
                        </a>
                    </div>
                </div>
                <div></div>
                </Flex>
        </>
    )
}