// Import the FontAwesomeIcon component
import Typewriter from 'typewriter-effect';
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import imgBG23 from "../../assets/img/trudefi/bg/23.jpg";
import imgHOW5 from "../../assets/img/howto/5.jpg";
import imgHOW6 from "../../assets/img/howto/6.png";
import imgHOW7 from "../../assets/img/howto/7.png";;


export default function Section7()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG23 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">
	
<div class="container">
<div class="row px-5 px-xl-2 justify-content-center">
  <div class="col-md-9 mt-5">
    <div class="img-wrap">
      <img src={imgHOW5} alt=""/>
    </div>	
  </div>	
  <div class="col-md-3 mt-5">
    <div class="row mt-5 mt-xl-0 justify-content-center">
        <div class="offset-xl-1 col-sm-8 col-md-6 col-lg-12 text-center text-xl-left" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
          <h5 style={{ color: "black", fontWeight: 600 }} class="mb-3">Stay Sharp(smart):                 </h5>
          <p style={{ color: "black", fontWeight: 600 }} class="mb-0 pb-0">Self-custody puts you in the driver´s seat.                  </p>	
          <ul>
          <li><p style={{ color: "black", fontWeight: 600 }} class="mb-0 pb-0">Don´t share your Private Keys or Seed Phrases.                </p>	</li>
          <li><p style={{ color: "black", fontWeight: 600 }} class="mb-0 pb-0">Admins & Team will never contact you.                </p>	</li>
          <li><p style={{ color: "black", fontWeight: 600 }} class="mb-0 pb-0">For inquiries, reach out to admins and Founders listed in the Team section.(link)                </p>	</li>
          </ul>
        </div>             
      </div>
  </div>
</div>	

<div class="row px-5 px-xl-2 justify-content-center">
                  <div class="col-lg-9 px-xl-0 order-first order-xl-first">
                    <div class="img-wrap">
                                    <img src={imgHOW6} alt=""/>
                    </div>	
                  </div>	
                  <div class="col-lg-3 mt-5 text-center text-xl-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.1s">
                      <div class="img-wrap">
                                    <img src={imgHOW7} alt=""/>
                      </div>	
                  </div>	
</div>		

</div>		
                </div>
            </Flex>
        </>
    )
}