// Import the FontAwesomeIcon component
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
{
    faTelegram
} from "@fortawesome/free-brands-svg-icons";
import '../styles/inner.css';
import imgBG12 from "../../assets/img/trudefi/bg/12.jpg";


import img1 from "../../assets/img/1.jpg";
import img2 from "../../assets/img/2.jpg";
import img3 from "../../assets/img/3.jpg";
import img4 from "../../assets/img/4.jpg";
import img5 from "../../assets/img/5.jpg";
import img6 from "../../assets/img/6.jpg";
import img7 from "../../assets/img/7.jpg";
import img8 from "../../assets/img/8.jpg";
import img10 from "../../assets/img/10.jpg";
import img11 from "../../assets/img/11.png";
import img12 from "../../assets/img/12.png";
import logo from "../../assets/img/logo.png";
import vid1 from "../../assets/img/videos/Media1.mp4";
import vid2 from "../../assets/img/videos/Media2.mp4";
import vid3 from "../../assets/img/videos/Media3.mp4";

import img2X5 from "../../assets/img/tru2x/5.png";
import img2X6 from "../../assets/img/tru2x/6.png";
import img2X7 from "../../assets/img/tru2x/7.png";
import img2X1 from "../../assets/img/tru2x/1.jpg";
import img2X10 from "../../assets/img/tru2x/10.jpg";
import img2X11 from "../../assets/img/tru2x/11.jpg";
import img2X12 from "../../assets/img/tru2x/12.jpg";
import img2X13 from "../../assets/img/tru2x/13.jpg";

import imgREF2 from "../../assets/img/referrals/2.png";
import imgREF3 from "../../assets/img/referrals/3.png";
// Homepage Section2 Section
export default function Section2()
{
    return (
        <>
            {/* PARALLAX TWO START */}
            <Flex className="section  over-hide swiper-container z-bigger" flexDirection="column" style={{ padding: "15px"}}>
			<div class="container">
			<div class="row px-5 px-xl-2 justify-content-center">
      <div  class="col-sm-5 col-md-5 col-lg-5 mt-5 mb-5 mr-5 px-xl-0  order-first order-xl-first">
      <div class="img-wrap">
						<img src={imgREF2} alt=""/>
					</div>	
					</div>	
                    <div class="ccol-sm-5 col-md-5 col-lg-5 mt-5 mb-5 px px-xl-0">
					<div class="img-wrap">
						<img src={imgREF3} alt=""/>
					</div>	
				</div>	
			</div>		
		</div>

                </Flex>
            {/* PARALLAX TWO END */}
        </>
    )
}