// Import the FontAwesomeIcon component
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
{
    faCircleDown
} from "@fortawesome/free-solid-svg-icons";
import Typewriter from 'typewriter-effect';
import '../styles/inner.css';
import imgBG13 from "../../assets/img/trudefi/bg/13.jpg";
import { Button, Overlay, AspectRatio } from '@mantine/core';

export default function Section3()
{
    return (
        <>
        
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG13 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">
                    <div  className="justify-content-center">
                    <Overlay m="20%" style={{ borderRadius: '1rem', padding: "150px"}} zIndex={-1}
            gradient="linear-gradient(145deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)"
            opacity={0.85}
            rounded
          />
                        <h2 className="text-white font-bold">🌍 Rhoncus odio</h2>
                        <div class="container">

<div class="column px-5 px-xl-2 justify-content-center">
    <Flex mt="60px" mb="60px" justifyContent="center" flexDirection="column" alignItems="center" width="100px">
      <Text fontSize="28px" color="#fff" bold mr="14px" >DeFi YIELD Added Today</Text>    
    </Flex>              
      
</div>
  </div>  

  <div class="container">
<div class="row px-5 px-xl-2 justify-content-center">
  <div class="col-md-4 mb-5 text-center" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">          
      <Text fontSize="36px" color="#fff" bold mr="14px" >0</Text> 
      <Text fontSize="36px" color="#fff" bold mr="14px" >PLS</Text> 
          </div>
</div>	      
<div class="row px-5 px-xl-2 justify-content-center">
  <div class="col-md-4 mb-5 text-center" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">          
                  <h5 class="mb-3">art direction</h5>
      <Text fontSize="36px" color="#fff" bold mr="14px" >13.87 B PLS</Text> 
          </div>
          <div class="col-md-4 mb-5 text-center" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">
                  <h5 class="mb-3">photography</h5>
                  <Text fontSize="36px" color="#fff" bold mr="14px" >13.87 B PLS</Text> 
          </div>
          <div class="col-md-4 mb-5 text-center" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">
                  <h5 class="mb-3">advertising</h5>
                  <Text fontSize="36px" color="#fff" bold mr="14px" >13.87 B PLS</Text> 
          </div>	
</div>	
</div>	
                        <a href="/whitepaper/whitepaper.pdf"
                            target="_blank"
                            className="bg-slate-300 text-black hover:bg-red-600 active:bg-red-900 font-bold uppercase text-base px-8 py-3 rounded-[24px] shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                            <span>primis in faucibus <FontAwesomeIcon icon={faCircleDown} className="ml-2" /></span>
                        </a>
                    </div>
                </div>
                <div></div>
                </Flex>
        </>
    )
}