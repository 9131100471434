import SeedSale from "./seedSale.js";
import imgBG20 from "../../assets/img/trudefi/bg/20.jpg";
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { Box, Divider, Grid, Col } from '@mantine/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
{
    faCircleDown
} from "@fortawesome/free-solid-svg-icons";
import Typewriter from 'typewriter-effect';

import imgHOW10 from "../../assets/img/howto/10.png";
import vid6 from "../../assets/img/videos/Media6.mp4"
import imgREF7 from "../../assets/img/referrals/7.png";

import styled from "styled-components";
// Homepage Section2 Section
export default function Section5()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG20 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
              <div className="text-center">
                <div  className="justify-content-center">

                  <div class="container">
                    <div class="col px-4 px-xl-2 mt-5 justify-content-center">

                      <div class="row px-4 px-xl-2 mt-5 justify-content-center">
                        <div class="col-sm-10 col-md-10 col-lg-10 mb-5 text-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">
                        <Flex mb="10px" justifyContent="center" flexDirection="column" alignItems="left" width="100px">
                          <Text fontSize="18px" color="cyan" bold mr="14px" >Market Buy & Deposit in your Token´s Claim Contract (TCC)                          </Text>    
                        </Flex>              
                        <Flex mt="1px" mb="50px" justifyContent="center" flexDirection="column" alignItems="center" width="100px">
                          <Text fontSize="13px" color="#fff" mr="14px" >
                          TruDeFi market buys your tokens in increasing amounts and makes them available for your holders to be claimed. The exact amount can´t be foretold, but the math allows only an upward trend. 
                          </Text>    
                        </Flex>      

                        <Flex mb="10px" justifyContent="center" flexDirection="column" alignItems="left" width="100px">
                          <Text fontSize="18px" color="lime" bold mr="14px" >New TVL of your Tokens in the TCC – The new total claimable token amount                          </Text>    
                        </Flex>              
                        <Flex mt="1px" mb="50px" justifyContent="center" flexDirection="column" alignItems="center" width="100px">
                          <Text fontSize="13px" color="#fff" mr="14px" >
                          As previous´ week´s claims have been conducted, it is expected that only holders representing half the holder share claim your tokens on our platform. The new TVL consist of last week´s spill over and newly added funds.
                          </Text>    
                        </Flex>  

                        <Flex mb="10px" justifyContent="center" flexDirection="column" alignItems="left" width="100px">
                          <Text fontSize="18px" color="orange" bold mr="14px" >Token amount spill over to the following week – unclaimed tokens                          </Text>    
                        </Flex>              
                        <Flex mt="1px" mb="50px" justifyContent="center" flexDirection="column" alignItems="center" width="100px">
                          <Text fontSize="13px" color="#fff" mr="14px" >
                          Holder Share of your token becomes more important and determines how much a holder can claim from the TCC. Since many holders are expected to be unaware of their free-claimable tokens, unclaimed funds remain in the claim pool. Next week´s market buys will be added to that and will represent next week´s new TVL.
                          </Text>    
                        </Flex>   

                        <Flex mb="10px" justifyContent="center" flexDirection="column" alignItems="center" width="100px">
                          <Divider color="#FDA228" sx={{ width: '25%' }} spacing={2}></Divider>
                        </Flex>

                        <Flex m="25px" style={{ height: '200px' }} flexDirection="column" alignItems="flex-start">
                            <ul className="list-disc" style={{ color: "white", fontSize: "16px"  }}>
                                <li>Significantly Increased TVL</li>
                                <li><p  class="mb-0 pb-0">Your token holders claim their share on our Tru2X UI.</p>	</li>
                                <li><p  class="mb-0 pb-0">Your token holders claim with the perspective of building a Residual Income with your tokens.</p>	</li>
                                <li><p  class="mb-0 pb-0">Token claim is embedded in the context of a successful collaboration with our hand-picked partners.                                </p>	</li>
                                <li><p  class="mb-0 pb-0">Advantages of possessing a higher holder share are pointed out.                                </p>	</li>
                                <li><p  class="mb-0 pb-0">The TCC is open source and verified. Your holders expect you to audit the TCC for their safety and give the green light before connecting their wallet and claiming.                                </p>	</li>
                            </ul>                            
                            </Flex>                        

                        </div>

                      </div>	
                    
                    </div>		
                  </div>

                </div>
              </div>
            </Flex>
        </>
    )
};

const Button = styled.button`
    border-radius: 8px;
    padding: 10px 18px;
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: ${({ primary }) => (primary ? "#d9511c" : "#171615")};
    color: ${({ primary }) => (primary ? "#f1ede9" : "#d9511c")};
    border: ${({ secondary }) => secondary && "1px solid #d9511c"};
`;