import React, { Suspense, useState, useRef, useEffect } from "react";
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import Swiper from 'swiper';
import 'animsition/dist/css/animsition.min.css';
import 'animsition/dist/js/animsition.min.js';
import $ from 'jquery';
// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
{
  faTelegram
} from "@fortawesome/free-brands-svg-icons";
import HomeSection from "./home.js";
import Section2 from "./section2.js";
import Section3 from "./section3.js";
import Section4 from "./section4.js";
import Section5 from "./section5.js";
import Section6 from "./section6.js";
import Section7 from "./section7.js";
import Section8 from "./section8.js";
import Section9 from "./section9.js";
import Footer from "./footer.js";


//import "./App.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/font-awesome.min.css";
//import "./assets/css/animsition.min.css";
import "../../assets/css/swiper.min.css";
import "../../assets/css/style.css";

import img1 from "../../assets/img/1.jpg";
import img2 from "../../assets/img/2.jpg";
import img3 from "../../assets/img/3.jpg";
import img4 from "../../assets/img/4.jpg";
import img5 from "../../assets/img/5.jpg";
import img6 from "../../assets/img/6.jpg";
import img7 from "../../assets/img/7.jpg";
import img8 from "../../assets/img/8.jpg";
import logo from "../../assets/img/logo.png";





function TruDefi() {
  
    const [scrollPos, setScrollPos] = useState(0);
    const cursor1 = useRef(null);
    const cursor2 = useRef(null);
    const cursor3 = useRef(null);
  
    useEffect(() => {

      const handleMouseMove = (e) => {
        if (cursor1.current && cursor2.current && cursor3.current) {
            const { clientX: x, clientY: y } = e;
            cursor1.current.style.left = `${x}px`;
            cursor1.current.style.top = `${y}px`;
            cursor2.current.style.left = `${x}px`;
            cursor2.current.style.top = `${y}px`;
            cursor3.current.style.left = `${x}px`;
            cursor3.current.style.top = `${y}px`;
        }
      };
  
      const handleMouseOver = () => {
        if (cursor2.current && cursor3.current) {
            cursor2.current.classList.add('hover');
            cursor3.current.classList.add('hover');
        }
      };
  
      const handleMouseOut = () => {
        if (cursor2.current && cursor3.current) {
            cursor2.current.classList.remove('hover');
            cursor3.current.classList.remove('hover');
        }
      };
  
      document.body.addEventListener('mousemove', handleMouseMove);
      const hoverTargets = document.querySelectorAll('.hover-target');
      hoverTargets.forEach((target) => {
        target.addEventListener('mouseover', handleMouseOver);
        target.addEventListener('mouseout', handleMouseOut);
      });
  
      return () => {
        document.body.removeEventListener('mousemove', handleMouseMove);
        hoverTargets.forEach((target) => {
          target.removeEventListener('mouseover', handleMouseOver);
          target.removeEventListener('mouseout', handleMouseOut);
        });
      };
    }, []);
  
    useEffect(() => {
      const scrollHandler = () => {
        const scrollPos = window.scrollY;
        setScrollPos(scrollPos);
      };
  
      window.addEventListener('scroll', scrollHandler);
      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    }, []);
  


    
    useEffect(() => {
    $('.animsition').animsition({
        inClass: 'fade-in',
        outClass: 'fade-out',
        inDuration: 1500,
        outDuration: 800,
        linkElement: 'a',
        loading: true,
        loadingParentElement: 'body', 
        loadingClass: 'animsition-loading',
        loadingInner: '', 
        timeout: false,
        timeoutCountdown: 5000,
        onLoadEvent: true,
        browser: [ 'animation-duration', '-webkit-animation-duration'],
        overlay : false,
        overlayClass : 'animsition-overlay-slide',
        overlayParentElement : 'body',
        transition: function(url){ window.location.href = url; }
      });
    }, []);




    useEffect(() => {
        // Page cursors
        const handleMouseMove = (n) => {
          if (t && e && i) {
            t.style.left = n.clientX + "px";
            t.style.top = n.clientY + "px";
            e.style.left = n.clientX + "px";
            e.style.top = n.clientY + "px";
            i.style.left = n.clientX + "px";
            i.style.top = n.clientY + "px";
          }
        };
    
        const t = document.getElementById('cursor');
        const e = document.getElementById('cursor2');
        const i = document.getElementById('cursor3');
    
        const handleMouseOver = () => {
          if (cursor2.current && cursor3.current) {
              cursor2.current.classList.add('hover');
              cursor3.current.classList.add('hover');
          }
        };
    
        const handleMouseOut = () => {
          if (cursor2.current && cursor3.current) {
              cursor2.current.classList.remove('hover');
              cursor3.current.classList.remove('hover');
          }
        };

        const addHover = () => {
          if (e && i) {
          e.classList.add('hover');
          i.classList.add('hover');
          }
        };
    
        const removeHover = () => {
          if (e && i) {
          e.classList.remove('hover');
          i.classList.remove('hover');
          }
        };

        document.body.addEventListener('mousemove', handleMouseMove);
    
        const hoverTargets = document.querySelectorAll('.hover-target');
        hoverTargets.forEach((target) => {
          target.addEventListener('mouseover', addHover);
          target.addEventListener('mouseout', removeHover);
        });
    
        // Parallax & fade on scroll
        const scrollBanner = () => {
          const handleScroll = () => {
            const scrollPos = window.scrollY;
            const parallaxFadeTop = document.querySelector('.parallax-fade-top');
            const parallaxTopShadow = document.querySelector('.parallax-top-shadow');
            const parallaxTop = document.querySelector('.parallax-top');
    
            if (parallaxFadeTop) {
              parallaxFadeTop.style.cssText = `top: ${scrollPos / 2}px; opacity: ${1 - scrollPos / 300};`;
            }
    
            if (parallaxTopShadow) {
              parallaxTopShadow.style.top = `${scrollPos / -2}px`;
            }
    
            if (parallaxTop) {
              parallaxTop.style.top = `${scrollPos / 2.2}px`;
            }
          };
    
          document.addEventListener('scroll', handleScroll);
        };
    
        scrollBanner();
    
        // Swiper initialization
        const swiper = new Swiper('.swiper-container', {
          scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
            dragSize: '19',
          },
          slidesPerView: 'auto',
          resistance: true,
          resistanceRatio: 0,
          speed: 800,
          autoplay: false,
          mousewheel: true,
          freeMode: true,
          grabCursor: true,
          touchStartPreventDefault: false,
          breakpoints: {
            1200: {
              freeMode: false,
            },
          },
        });
    
        // Hero slider mouse events
        const heroSlider = document.getElementById('hero-slider');
        if (heroSlider) {
          heroSlider.addEventListener('mousedown', () => document.body.classList.add('scale-up'));
          heroSlider.addEventListener('mouseup', () => document.body.classList.remove('scale-up'));
        }
    
        // Navigation
        const initNavigation = () => {
          const body = document.querySelector('body');
          const menu = document.querySelector('.menu-icon');
    
          const toggleClass = (element, stringClass) => {
            if (element.classList.contains(stringClass)) {
              element.classList.remove(stringClass);
            } else {
              element.classList.add(stringClass);
            }
          };
    
          if (menu) {
            menu.addEventListener('click', () => toggleClass(body, 'nav-active'));
          }
        };
    
        initNavigation();
    
        // Scroll back to top
        const scrollToTop = () => {
          const offset = 300;
          const duration = 400;
    
          const handleScroll = () => {
            const scrollTopButton = document.querySelector('.scroll-to-top');
            if (scrollTopButton) {
              if (window.scrollY > offset) {
                scrollTopButton.classList.add('active-arrow');
              } else {
                scrollTopButton.classList.remove('active-arrow');
              }
            }
          };
    
          const handleClick = (event) => {
            event.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          };
    
          window.addEventListener('scroll', handleScroll);
    
          const scrollTopButton = document.querySelector('.scroll-to-top');
          if (scrollTopButton) {
            scrollTopButton.addEventListener('click', handleClick);
          }
        };
    
        scrollToTop();
    
        // Hero Case Study Hover Effect
        const caseStudyHoverEffect = () => {
          const handleMouseEnter = (index) => {
            const activeName = document.querySelector('.case-study-name.active');
            const showImage = document.querySelector('.case-study-images li.show');
            const newImage = document.querySelector(`.case-study-images li:nth-child(${index})`);
            const newName = document.querySelector(`.case-study-name:nth-child(${index})`);
    
            if (activeName) activeName.classList.remove('active');
            if (showImage) showImage.classList.remove('show');
            if (newImage) newImage.classList.add('show');
            if (newName) newName.classList.add('active');
          };
    
          for (let i = 1; i <= 10; i++) {
            const caseStudyName = document.querySelector(`.case-study-name:nth-child(${i})`);
            if (caseStudyName) {
              caseStudyName.addEventListener('mouseenter', () => handleMouseEnter(i));
            }
          }
    
          const firstCaseStudyName = document.querySelector('.case-study-name:nth-child(1)');
          if (firstCaseStudyName) {
            firstCaseStudyName.dispatchEvent(new Event('mouseenter'));
          }
        };
    
        caseStudyHoverEffect();
    
        // Clean up event listeners on component unmount
        return () => {
          document.body.removeEventListener('mousemove', handleMouseMove);
          hoverTargets.forEach((target) => {
            target.removeEventListener('mouseover', addHover);
            target.removeEventListener('mouseout', removeHover);
          });
        };
      }, []);




    const [currentIndex, setCurrentIndex] = useState(0);
    const slides = [
      { id: 1, content: "Slide 1" },
      { id: 2, content: "Slide 2" },
      { id: 3, content: "Slide 3" }
    ];
  
    const nextSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    };
  
    const prevSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? slides.length - 1 : prevIndex - 1
      );
    };  


    return (


<>
<main>
<HomeSection />
<Section2 />
<Section3 />
<Section4 />
        <Section5 />
        <Section6 />        
        <Section7 />
        <Section8 />
        <Section9 />
        <Footer />
</main>




</>


    );
};


export default TruDefi;
