// Import the FontAwesomeIcon component
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
{
    faCircleDown
} from "@fortawesome/free-solid-svg-icons";
import Typewriter from 'typewriter-effect';
import '../styles/inner.css';
import imgBG13 from "../../assets/img/trudefi/bg/13.jpg";
import { Button, Overlay, AspectRatio } from '@mantine/core';

export default function Section3()
{
    return (
        <>
        
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG13 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">
                    <div  className="justify-content-center">
                    <Overlay m="20%" style={{ borderRadius: '1rem', padding: "150px"}} zIndex={-1}
            gradient="linear-gradient(145deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)"
            opacity={0.85}
            rounded
          />
                        <h2 className="text-white font-bold">🌍 Realizing the desire to DOUBLE our means is as old as the human race.</h2>
                        <h4 className="lead text-white font-bold">
                            <Typewriter
                                options={{
                                    strings: ["enter.", "compound.", "double you crypto."],
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </h4>
                        <p className="text-white mb-10"> Immerse yourself in a decentralised, successful solution to that desire, <br/> powered by advanced logic and precise computation.</p>
                        <a href="/whitepaper/whitepaper.pdf"
                            target="_blank"
                            className="bg-slate-300 text-black hover:bg-red-600 active:bg-red-900 font-bold uppercase text-base px-8 py-3 rounded-[24px] shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                            <span>Double Now<FontAwesomeIcon icon={faCircleDown} className="ml-2" /></span>
                        </a>
                    </div>
                </div>
                <div></div>
                </Flex>
        </>
    )
}