import SeedSale from "./seedSale.js";
import imgBG20 from "../../assets/img/trudefi/bg/20.jpg";
import { Link, Flex, Text, Input } from 'crox-new-uikit';
// Homepage Section2 Section
export default function Section4()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG20 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="grid grid-flow-row auto-rows-min sm:grid-flow-col justify-around">
                <div class="container">

<div class="row px-4 px-xl-2 mt-5 justify-content-center">
  <div class="col-md-10 mb-5 text-center" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">          
      <Text fontSize="36px" color="#fff" bold mr="14px" >Numbers in Relevant Context</Text> 
          </div>
</div>	      
<div class="row px-4 px-xl-2 mt-5 justify-content-center">
  <div class="col-md-4 mb-5 text-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">
      <div class="offset-xl-1  text-center text-xl-left" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
                          <h5 class="mb-3">Auction Proceeds Allocation                </h5>
                          <p class="mb-0 pb-0">Daily proceeds from the Auction Lobby are allocated strategically to support various aspects of the Tru2X ecosystem:

<br/>80% Daily distributed Miner rewards
<br/>14% Daily allocated to the Benevolent wallet for yield generation, ensuring a 2X for every deposit
<br/>6%  Marketing & operational expenses for a competitive presence in the DeFi space
</p>	
                  </div> 
          </div>

  <div class="col-md-4 mb-5 text-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">
      <div class="offset-xl-1 text-center text-xl-left" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
                          <h5 class="mb-3">Daily Added DeFi Yield                </h5>
                          <p class="mb-0 pb-0">A BW, funded by daily Auction Proceeds, accrues daily funds (with no expectations) to help support the miners by depositing a part of its yield as part of the overall distribution function. The BW will only grow and increase its yield, becoming a significant daily depositor in the Auction House.

<br/>1M daily Tru Shares are distributed to all deposits in relation to their deposit volume. Shares allocated to the yield deposit are deleted by the system & don´t participate in the mining process.</p>	
                  </div> 
          </div>
  <div class="col-md-4 mb-5 text-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">
      <div class="offset-xl-1 text-center text-xl-left" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
                          <h5 class="mb-3">Mining Process 1-0-1                </h5>
                          <p class="mb-0 pb-0">Mining operations in the Tru2X protocols stand on essential components designed for a high-end platform architecture sustaining the overall health and value of the ecosystem.

          <br/>Daily max. 1M Tru Shares are added to the Mining Pool.
          <br/>Shares of Miners exiting the mining process at a 2X or earlier are removed from the dividend distribution. Miners can be ended at any time.
          <br/>Daily accrued dividends can be compounded. 
          <br/>It is recommended to read the White Paper
</p>	
                  </div> 
          </div>

</div>	
</div>	
                </div>
            </Flex>
        </>
    )
}