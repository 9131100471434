import React, { Suspense, useState, useRef, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import PageLoader from "./components/PageLoader";

import Header from "./components/ConnecButton";
import Sidebar from "./components/Sidebar";
import Home from "./view/Home";
import "./App.scss";
//import { NFTCharacter, Lobby, Libby } from "./view/NFTGame";
import { Libby } from "./view/NFTGame";

import GlobalStyle from "./assets/style/css/GlobalStyle";

import { WalletConnect } from "./components/Gadget";
//// import './assets/style/css/globals.css';
import { Flex } from "crox-new-uikit";
import useMediaQuery from '@mui/material/useMediaQuery';
import abi from "./utils/StakingToken.json";
//import Profile from "./Profile";
////import "./App.css";
import {
    WagmiConfig,
    createClient,
    defaultChains,
    configureChains,
    useAccount,
} from "wagmi";
import { client } from "./libraries/client";
import Landing from "./view/Landing";
import TruDefi from "./view/TruDefi";
import Tru2X from "./view/Tru2X";
import HowTo2X from "./view/HowTo2X";
import Funding from "./view/Funding";
import PulseMap from "./view/PulseMap";
import Referrals from "./view/Referrals";
import Partners from "./view/Partners";
import HeaderSite from "./components/HeaderSite";
import Footer from "./components/Footer";


import Swiper from 'swiper';
import 'animsition/dist/css/animsition.min.css';
import 'animsition/dist/js/animsition.min.js';
import $ from 'jquery';


//import "./App.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
//import "./assets/css/animsition.min.css";
import "./assets/css/swiper.min.css";
import "./assets/css/style.css";

import img1 from "./assets/img/1.jpg";
import img2 from "./assets/img/2.jpg";
import img3 from "./assets/img/3.jpg";
import img4 from "./assets/img/4.jpg";
import img5 from "./assets/img/5.jpg";
import img6 from "./assets/img/6.jpg";
import img7 from "./assets/img/7.jpg";
import img8 from "./assets/img/8.jpg";
import img15 from "./assets/img/15.jpg";
import img16 from "./assets/img/16.jpg";
import imgBackground from "./assets/img/tru2x/background.png";
import imgBackground2 from "./assets/img/howto/background.png";
import logo from "./assets/img/logo.png";
////////////////////////////////////////////////////////
import '@rainbow-me/rainbowkit/styles.css';
import { ledgerWallet, metaMaskWallet, walletConnectWallet, trustWallet, coinbaseWallet, rainbowWallet } from '@rainbow-me/rainbowkit/wallets';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import
{
  apiProvider,
  
  getDefaultWallets,  
  RainbowKitProvider,
  darkTheme
} from '@rainbow-me/rainbowkit';


import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { Analytics } from '@vercel/analytics/react';
/*
export const binanceChainTestNet = {
  id: 97,
  name: 'BNB Smart Chain Testnet',
  network: 'bsc-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Chain Native Token',
    symbol: 'tBNB',
  },
  rpcUrls: {
    public: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
    default: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
  },
  blockExplorers: {
    default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
  },
  testnet: true,
} as const Chain;
*/


import {
  polygonMumbai,
  goerli,
  polygon,
  mainnet,
  bsc,
  avalanche,
  moonbeam,
  evmos,
  dogechain,
  okc,
  fantom,
  foundry,
  pulsechainV4,
  base,
  pulsechain,
} from "@wagmi/chains";


const { chains, provider } = configureChains(
  //[binanceChainTestNet],
  [
    polygonMumbai,
    goerli,
    polygon,
    mainnet,
    bsc,
    avalanche,
    moonbeam,
    evmos,
    dogechain,
    okc,
    fantom,
    foundry,
    pulsechainV4,
    base,
    pulsechain,
  ],  
  [
    alchemyProvider({ apiKey: process.env.NEXT_PUBLIC_ALCHEMY_ID }),
    publicProvider()
  ]
 
);

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({ chains }),
      ledgerWallet({ chains }),
      trustWallet({ chains }),
    ],
    chains,    
  },
  {
    groupName: 'Others',
    wallets: [
      coinbaseWallet({ chains, appName: 'Presale Dapp' }),
      walletConnectWallet({ chains }),
      rainbowWallet({ chains }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

const App = () => {
    const [collapse, setCollapse] = useState(true);
    const [toggle, setToggle] = useState(false);
    const [isWalletConnected, setIsWalletConnected] = useState(false);
    const [userName, setUserName] = useState("");
    const [displayedUserName, setDisplayedUserName] = useState("initial name");
    const [error, setError] = useState(null);
    const contractAddress = "0x610178dA211FEF7D417bC0e6FeD39F05609AD788";
    const contractABI = abi.abi;
    const location = useLocation();
    const { address } = useAccount();
    console.log(location);
    const handleChild = (status) => {
        console.log("Status", status);
        status ? setIsWalletConnected(true) : setIsWalletConnected(false);
    };

    const isMobile = useMediaQuery("(max-width: 600px)");

    ///////////////////////////////////////////////////////////////////

    const [scrollPos, setScrollPos] = useState(0);
    const cursor1 = useRef(null);
    const cursor2 = useRef(null);
    const cursor3 = useRef(null);
  
    useEffect(() => {

      const handleMouseMove = (e) => {
        if (cursor1.current && cursor2.current && cursor3.current) {
            const { clientX: x, clientY: y } = e;
            cursor1.current.style.left = `${x}px`;
            cursor1.current.style.top = `${y}px`;
            cursor2.current.style.left = `${x}px`;
            cursor2.current.style.top = `${y}px`;
            cursor3.current.style.left = `${x}px`;
            cursor3.current.style.top = `${y}px`;
        }
      };
  
      const handleMouseOver = () => {
        if (cursor2.current && cursor3.current) {
            cursor2.current.classList.add('hover');
            cursor3.current.classList.add('hover');
        }
      };
  
      const handleMouseOut = () => {
        if (cursor2.current && cursor3.current) {
            cursor2.current.classList.remove('hover');
            cursor3.current.classList.remove('hover');
        }
      };
  
      document.body.addEventListener('mousemove', handleMouseMove);
      const hoverTargets = document.querySelectorAll('.hover-target');
      hoverTargets.forEach((target) => {
        target.addEventListener('mouseover', handleMouseOver);
        target.addEventListener('mouseout', handleMouseOut);
      });
  
      return () => {
        document.body.removeEventListener('mousemove', handleMouseMove);
        hoverTargets.forEach((target) => {
          target.removeEventListener('mouseover', handleMouseOver);
          target.removeEventListener('mouseout', handleMouseOut);
        });
      };
    }, []);
  
    useEffect(() => {
      const scrollHandler = () => {
        const scrollPos = window.scrollY;
        setScrollPos(scrollPos);
      };
  
      window.addEventListener('scroll', scrollHandler);
      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    }, []);
  
    useEffect(() => {
      const swiper = new Swiper('.swiper-container', {
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: false,
          draggable: true,
          dragSize: '19',
        },
        slidesPerView: 'auto',
        resistance: true,
        resistanceRatio: 0,
        speed: 800,
        autoplay: false,
        mousewheel: true,
        freeMode: true,
        grabCursor: true,
        touchStartPreventDefault: false,
        breakpoints: {
          1200: {
            freeMode: false,
          },
        },
      });
  
      const handleMouseDown = () => document.body.classList.add('scale-up');
      const handleMouseUp = () => document.body.classList.remove('scale-up');
  
      const heroSlider = document.getElementById('hero-slider');
      if(heroSlider){
      heroSlider.addEventListener('mousedown', handleMouseDown);
      heroSlider.addEventListener('mouseup', handleMouseUp);
      }
  
      return () => {
        if(swiper){
        swiper.destroy();
        }
        if(heroSlider){
        heroSlider.removeEventListener('mousedown', handleMouseDown);
        heroSlider.removeEventListener('mouseup', handleMouseUp);
        }
      };

    }, []);

    
    useEffect(() => {
    $('.animsition').animsition({
        inClass: 'fade-in',
        outClass: 'fade-out',
        inDuration: 1500,
        outDuration: 800,
        linkElement: 'a',
        loading: true,
        loadingParentElement: 'body', 
        loadingClass: 'animsition-loading',
        loadingInner: '', 
        timeout: false,
        timeoutCountdown: 5000,
        onLoadEvent: true,
        browser: [ 'animation-duration', '-webkit-animation-duration'],
        overlay : false,
        overlayClass : 'animsition-overlay-slide',
        overlayParentElement : 'body',
        transition: function(url){ window.location.href = url; }
      });
    }, []);




    useEffect(() => {
        // Page cursors
        const handleMouseMove = (n) => {
          if (t && e && i) {
            t.style.left = n.clientX + "px";
            t.style.top = n.clientY + "px";
            e.style.left = n.clientX + "px";
            e.style.top = n.clientY + "px";
            i.style.left = n.clientX + "px";
            i.style.top = n.clientY + "px";
          }
        };
    
        const t = document.getElementById('cursor');
        const e = document.getElementById('cursor2');
        const i = document.getElementById('cursor3');
    
        const addHover = () => {
            if (e && i) {
          e.classList.add('hover');
          i.classList.add('hover');
            }
        };
    
        const removeHover = () => {
            if (e && i) {
          e.classList.remove('hover');
          i.classList.remove('hover');
            }
        };
    
        document.body.addEventListener('mousemove', handleMouseMove);
    
        const hoverTargets = document.querySelectorAll('.hover-target');
        hoverTargets.forEach((target) => {
          target.addEventListener('mouseover', addHover);
          target.addEventListener('mouseout', removeHover);
        });
    
        // Parallax & fade on scroll
        const scrollBanner = () => {
          const handleScroll = () => {
            const scrollPos = window.scrollY;
            const parallaxFadeTop = document.querySelector('.parallax-fade-top');
            const parallaxTopShadow = document.querySelector('.parallax-top-shadow');
            const parallaxTop = document.querySelector('.parallax-top');
    
            if (parallaxFadeTop) {
              parallaxFadeTop.style.cssText = `top: ${scrollPos / 2}px; opacity: ${1 - scrollPos / 300};`;
            }
    
            if (parallaxTopShadow) {
              parallaxTopShadow.style.top = `${scrollPos / -2}px`;
            }
    
            if (parallaxTop) {
              parallaxTop.style.top = `${scrollPos / 2.2}px`;
            }
          };
    
          document.addEventListener('scroll', handleScroll);
        };
    
        scrollBanner();
    
        // Swiper initialization
        const swiper = new Swiper('.swiper-container', {
          scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
            dragSize: '19',
          },
          slidesPerView: 'auto',
          resistance: true,
          resistanceRatio: 0,
          speed: 800,
          autoplay: false,
          mousewheel: true,
          freeMode: true,
          grabCursor: true,
          touchStartPreventDefault: false,
          breakpoints: {
            1200: {
              freeMode: false,
            },
          },
        });
    
        // Hero slider mouse events
        const heroSlider = document.getElementById('hero-slider');
        if (heroSlider) {
          heroSlider.addEventListener('mousedown', () => document.body.classList.add('scale-up'));
          heroSlider.addEventListener('mouseup', () => document.body.classList.remove('scale-up'));
        }
    
        // Navigation
        const initNavigation = () => {
          const body = document.querySelector('body');
          const menu = document.querySelector('.menu-icon');
    
          const toggleClass = (element, stringClass) => {
            if (element.classList.contains(stringClass)) {
              element.classList.remove(stringClass);
            } else {
              element.classList.add(stringClass);
            }
          };
    
          if (menu) {
            menu.addEventListener('click', () => toggleClass(body, 'nav-active'));
          }
        };
    
        initNavigation();
    
        // Scroll back to top
        const scrollToTop = () => {
          const offset = 300;
          const duration = 400;
    
          const handleScroll = () => {
            const scrollTopButton = document.querySelector('.scroll-to-top');
            if (scrollTopButton) {
              if (window.scrollY > offset) {
                scrollTopButton.classList.add('active-arrow');
              } else {
                scrollTopButton.classList.remove('active-arrow');
              }
            }
          };
    
          const handleClick = (event) => {
            event.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          };
    
          window.addEventListener('scroll', handleScroll);
    
          const scrollTopButton = document.querySelector('.scroll-to-top');
          if (scrollTopButton) {
            scrollTopButton.addEventListener('click', handleClick);
          }
        };
    
        scrollToTop();
    
        // Hero Case Study Hover Effect
        const caseStudyHoverEffect = () => {
          const handleMouseEnter = (index) => {
            const activeName = document.querySelector('.case-study-name.active');
            const showImage = document.querySelector('.case-study-images li.show');
            const newImage = document.querySelector(`.case-study-images li:nth-child(${index})`);
            const newName = document.querySelector(`.case-study-name:nth-child(${index})`);
    
            if (activeName) activeName.classList.remove('active');
            if (showImage) showImage.classList.remove('show');
            if (newImage) newImage.classList.add('show');
            if (newName) newName.classList.add('active');
          };
    
          for (let i = 1; i <= 10; i++) {
            const caseStudyName = document.querySelector(`.case-study-name:nth-child(${i})`);
            if (caseStudyName) {
              caseStudyName.addEventListener('mouseenter', () => handleMouseEnter(i));
            }
          }
    
          const firstCaseStudyName = document.querySelector('.case-study-name:nth-child(1)');
          if (firstCaseStudyName) {
            firstCaseStudyName.dispatchEvent(new Event('mouseenter'));
          }
        };
    
        caseStudyHoverEffect();
    
        // Clean up event listeners on component unmount
        return () => {
          document.body.removeEventListener('mousemove', handleMouseMove);
          hoverTargets.forEach((target) => {
            target.removeEventListener('mouseover', addHover);
            target.removeEventListener('mouseout', removeHover);
          });
        };
      }, []);




    const [currentIndex, setCurrentIndex] = useState(0);
    const slides = [
      { id: 1, content: "Slide 1" },
      { id: 2, content: "Slide 2" },
      { id: 3, content: "Slide 3" }
    ];
  
    const nextSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    };
  
    const prevSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? slides.length - 1 : prevIndex - 1
      );
    };

    return (
        <div className="App">
            <GlobalStyle />
            
            
                {/* <Routes> */}
                {/* <Route
                        path="/landing"
                        element={<Landing collapse={collapse} />}
                    /> */}

                <Flex
                    flexDirection="column"
                    style={{
                        minHeight: "100vh",
                        justifyContent: "space-between",
                    }}
                >
                    {/* <Header
                        handleChild={handleChild}
                        toggle={toggle}
                        setToggle={setToggle}
                    /> */}
                    

                    <div className="animsition">
                    <header className="cd-header">
    <div className="header-wrapper">
        <div className="logo-wrap">
        <a href="/" className="hover-target animsition-link">
            <img src={logo} alt="Logo" />
        </a>
        </div>
        <div className="nav-but-wrap">
            <div className="menu-icon hover-target">
                <span className="menu-icon__line menu-icon__line-left"></span>
                <span className="menu-icon__line"></span>
                <span className="menu-icon__line menu-icon__line-right"></span>
            </div>					
        </div>					
    </div>				
</header>


<div className="nav">
    <div className="nav__content">
        <div className="curent-page-name-shadow">TruDefi</div>
        <ul className="nav__list">
            <li className="nav__list-item active-nav"><a data-toggle="collapse" href="#collapseSub" className="hover-target" role="button" aria-expanded="false" aria-controls="collapseSub">Networks</a></li>
            <li className="nav__list-item"><a href="/libby" className="hover-target animsition-link">The Lobby</a></li>
            <li className="nav__list-item"><a href="blog.html" className="hover-target animsition-link">2x Miner</a></li>
            <li className="nav__list-item"><a href="contact.html" className="hover-target animsition-link">About Us</a></li>
        </ul>
    </div>
</div>	                    
                   
                 

                        {/* <Sidebar
                            collapse={collapse}
                            setCollapse={setCollapse}
                            toggle={toggle}
                            setToggle={setToggle}
                        /> */}
                        <WagmiConfig client={client}>


                        <RainbowKitProvider coolMode chains={chains} theme={darkTheme(
        {
          accentColor: '#E02424',
          accentColorForeground: 'white',
          borderRadius: 'large',
          fontStack: 'system',
        }
      )}>
                            {/*<HeaderSite />*/}

                        {!address ? (
                            <Flex
                                justifyContent="center"
                                flexDirection="column"
                                alignItems="center"
                                mt={isMobile ? 100 : -30}
                            >
                                <WalletConnect />
                            </Flex>
                        ) : (
                            <Suspense fallback={<PageLoader />}>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={<Landing collapse={collapse} />}
                                    />
                                    <Route
                                        path="/landing"
                                        element={
                                            <Landing collapse={collapse} />
                                        }
                                    />
                                    <Route
                                        path="/trudefi"
                                        element={<TruDefi collapse={collapse} />}
                                    />  

                                    <Route
                                        path="/libby"
                                        element={<Libby collapse={collapse} />}
                                    />   
                                    <Route
                                        path="/tru2x"
                                        element={
                                        
                                            
                                            <Tru2X collapse={collapse} />
                                            
                                        }
                                        
                                        
                                    />   
                                    <Route
                                        path="/howto2x"
                                        element={
                                        <HowTo2X collapse={collapse} />
                                        }
                                    />   
                                    <Route
                                        path="/funding"
                                        element={<Funding collapse={collapse} />}
                                    />  
                                    <Route
                                        path="/pulsemap"
                                        element={<PulseMap collapse={collapse} />}
                                    />  
                                    <Route
                                        path="/referrals"
                                        element={<Referrals collapse={collapse} />}
                                    />  
                                    <Route
                                        path="/partners"
                                        element={<Partners collapse={collapse} />}
                                    />  
                                                                                                                                                                                                                                                                                                                                   

                                </Routes>
                                
                            </Suspense>
                        )}
                        </RainbowKitProvider>                          
                        </WagmiConfig>
                    </div>

                </Flex>
                {/* </Routes> */}

        </div>
        
    );
};

export default App;
