import Typewriter from 'typewriter-effect';
import imgBG26 from "../../assets/img/trudefi/bg/26.jpg";
import vid3 from "../../assets/img/videos/Media3.mp4";

import { Link, Flex, Text, Input } from 'crox-new-uikit';
export default function Section8()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG26 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">
                <div class="container">
        <div class="col-12 mt-5" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.1s">
            <div class="video-section hover-target">
							<figure> 
                <iframe style={{width: "100%", aspectRatio: "16 / 9"}}  src={vid3} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</figure>
						</div>	
			  </div>	
		  </div>  
                </div>
            </Flex>
        </>
    )
}