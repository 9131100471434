import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
@tailwind base;
@tailwind components;
@tailwind utilities;

htm,
body {
  color: #999999;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  color: #000;
  margin: 0 0 15px;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  color: #ffc112;
}

h1.lead {
  font-size: 160px;
  letter-spacing: 0;
  line-height: 180px;
  font-weight: 700;
}

h2.lead {
  font-size: 50px;
  letter-spacing: 0;
  line-height: 60px;
  font-weight: 700;
}

h3.lead {
  font-size: 50px;
  letter-spacing: 0;
  line-height: 60px;
}

h4.lead {
  font-size: 35px;
  letter-spacing: 0;
  line-height: 50px;
}

h5.lead {
  font-size: 34px;
  letter-spacing: 0;
  line-height: 40px;
}

h6.lead {
  font-size: 20px;
  letter-spacing: 8px;
  line-height: 36px;
}

h1 {
  font-size: 30px;
  line-height: 50px;
}

h2 {
  font-size: 26px;
  letter-spacing: 0;
  line-height: 36px;
}

h3 {
  font-size: 24px;
  letter-spacing: 0;
  line-height: 40px;
}

h4 {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 34px;
}

h5 {
  font-size: 16px;
  letter-spacing: 6.4px;
  line-height: 20px;
}

h6 {
  font-size: 14px;
  letter-spacing: 5.6px;
  line-height: 28px;
}

.imagesDiv {
  position: relative;
  overflow: hidden;
}

.imagesDiv img {
  width: 100%;
  height: auto;
}

blockquote {
  border-left: 2px solid #fd0060;
  margin: 60px 0 50px;
  padding: 0 65px 0 20px;
}

blockquote p {
  font-size: 22px;
  color: #000000;
}

.tokenomicsDiv {
  height: 30vh;
  width: 70vw;
  position: relative;
}

@media (min-width: 768px) {
  .tokenomicsDiv {
    height: auto;
    width: auto;
  }

  h1.lead {
    font-size: 160px;
    letter-spacing: 0;
    line-height: 180px;
    font-weight: 700;
  }
  
  h2.lead {
    font-size: 75px;
    letter-spacing: 0;
    line-height: 90px;
    font-weight: 700;
  }
  
  h3.lead {
    font-size: 50px;
    letter-spacing: 0;
    line-height: 60px;
  }
  
  h4.lead {
    font-size: 40px;
    letter-spacing: 0;
    line-height: 50px;
  }
  
  h5.lead {
    font-size: 34px;
    letter-spacing: 0;
    line-height: 40px;
  }
  
  h6.lead {
    font-size: 20px;
    letter-spacing: 8px;
    line-height: 36px;
  }
  
  h1 {
    font-size: 30px;
    line-height: 50px;
  }
  
  h2 {
    font-size: 26px;
    letter-spacing: 0;
    line-height: 36px;
  }
  
  h3 {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 40px;
  }
  
  h4 {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 34px;
  }
  
  h5 {
    font-size: 16px;
    letter-spacing: 6.4px;
    line-height: 20px;
  }
  
  h6 {
    font-size: 14px;
    letter-spacing: 5.6px;
    line-height: 28px;
  }
}

`;

export default GlobalStyle;
