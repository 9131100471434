import Typewriter from 'typewriter-effect';
import imgBG26 from "../../assets/img/trudefi/bg/26.jpg";
import vid4 from "../../assets/img/videos/Media4.mp4";
import imgHOW6 from "../../assets/img/howto/6.png";
import imgHOW7 from "../../assets/img/howto/7.png";

import { Link, Flex, Text, Input } from 'crox-new-uikit';
export default function Section8()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG26 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">
                <div class="container">
                        <div class="row px-5 px-xl-2 justify-content-center">
                        <div class="video-section hover-target">
							<figure> 
                <iframe width="700px" height="400px" src={vid4} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</figure>
						</div>	
	
                        </div>		
                    </div>
                </div>
            </Flex>
        </>
    )
}