import SeedSale from "./seedSale.js";
import imgBG20 from "../../assets/img/trudefi/bg/20.jpg";
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { Box, Divider, Grid, Col } from '@mantine/core';

import imgHOW9_1 from "../../assets/img/howto/9-1.jpg";
import imgHOW9_2 from "../../assets/img/howto/9-2.jpg";
import imgHOW9_3 from "../../assets/img/howto/9-3.jpg";
import imgHOW9_4 from "../../assets/img/howto/9-4.jpg";

import styled from "styled-components";
// Homepage Section2 Section
export default function Section4()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG20 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="grid grid-flow-row auto-rows-min sm:grid-flow-col justify-around">
                <div class="container">

<Grid mt="50px" gutter={10}>
<Col span={6} md={3} lg={3} >
  <Flex flexDirection="column" style={{ backgroundImage:"url(" + imgHOW9_1 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
    <Flex style={{ height: '200px' }}  flexDirection="column" alignItems="flex-start">
      <h5 style={{ color: "black", fontWeight: 600 }} class="mb-3">Deposit Your Crypto in the Auction House        </h5>
    </Flex>
    <Flex style={{ height: '200px' }} flexDirection="column" alignItems="flex-start">
      <p style={{ color: "black", fontWeight: 600 }} class="mb-0 pb-0">Effortlessly deposit your PLS and manage your shares through our intuitive Auction House. Click to learn more about the process and how to maximize your dividends.        </p>	
    </Flex>
    <Flex style={{ height: '200px' }}  justifyContent="center" alignItems="flex-end">
      <Button primary className={`orange_btn btn_1`} size="large" outline rounded>
        Explore Auction House
      </Button>
    </Flex>
  </Flex>
</Col>
<Col span={6} md={3} lg={3} >
  <Flex flexDirection="column" style={{ backgroundImage:"url(" + imgHOW9_2 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
    <Flex style={{ height: '200px' }} flexDirection="column" alignItems="flex-start">
      <h5 style={{ color: "black", fontWeight: 600 }} class="mb-3">Manage and Withdraw Your Crypto from the Mining Pool            </h5>
    </Flex>
    <Flex style={{ height: '200px' }} flexDirection="column" alignItems="flex-start">
      <p style={{ color: "black", fontWeight: 600 }} class="mb-0 pb-0">Efficiently manage your miners and withdraw your PLS anytime without penalties. Click to explore the full range of features and options available in the Miner Section.            </p>	
    </Flex>
    <Flex style={{ height: '200px' }}  justifyContent="center" alignItems="flex-end">
      <Button primary className={`orange_btn btn_1`} size="large" outline rounded>
      Manage Mining Pool
      </Button>
    </Flex>
  </Flex>
</Col>
<Col span={6} md={3} lg={3} >
  <Flex flexDirection="column" style={{ backgroundImage:"url(" + imgHOW9_3 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
    <Flex style={{ height: '200px' }} flexDirection="column" alignItems="flex-start">
      <h5 style={{ color: "black", fontWeight: 600 }} class="mb-3">Understanding Tru2X: No Platform Token, Just Tru Shares            </h5>
    </Flex>
    <Flex style={{ height: '200px' }} flexDirection="column" alignItems="flex-start">
      <p style={{ color: "black", fontWeight: 600 }} class="mb-0 pb-0">Discover how Tru2X operates with Tru Shares as the key medium for your PLS deposits. Click to explore how the Auction House model influences the time to your ROI and the platform's unique structure.            </p>	
    </Flex>
    <Flex style={{ height: '200px' }}  justifyContent="center" alignItems="flex-end">
      <Button primary className={`orange_btn btn_1`} size="large" outline rounded>
      White Paper
      </Button>
    </Flex>
  </Flex>
</Col>
<Col span={6} md={3} lg={3} >
  <Flex flexDirection="column" style={{ backgroundImage:"url(" + imgHOW9_4 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
    <Flex style={{ height: '200px' }} flexDirection="column" alignItems="flex-start">
      <h5 style={{ color: "black", fontWeight: 600 }} class="mb-3">Compounding: The Unsung Hero of Long-Term Gains</h5>
    </Flex>
    <Flex style={{ height: '200px' }} flexDirection="column" alignItems="flex-start">
      <p style={{ color: "black", fontWeight: 600 }} class="mb-0 pb-0">Though it's easy to set and forget for your 2X process, you might want to explore the option of compounding your daily dividends. Compounding follows an exponential growth model, a concept often underestimated because it’s hard for our minds to fully grasp—but its long-term potential is powerful.            </p>	
    </Flex>
    <Flex style={{ height: '200px' }}  justifyContent="center" alignItems="flex-end">
      <Button primary className={`orange_btn btn_1`} size="large" outline rounded>
      Compound Your Dividends
      </Button>
    </Flex>
  </Flex>
</Col>
</Grid>           
</div>
                </div>
            </Flex>
        </>
    )
};

const Button = styled.button`
    border-radius: 8px;
    padding: 10px 18px;
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: ${({ primary }) => (primary ? "#d9511c" : "#171615")};
    color: ${({ primary }) => (primary ? "#f1ede9" : "#d9511c")};
    border: ${({ secondary }) => secondary && "1px solid #d9511c"};
`;