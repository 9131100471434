import React from "react";

import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Flex, Link, Text } from "crox-new-uikit";
import useMediaQuery from "use-mediaquery";
import "../assets/style/scss/react-pro-sidebar.scss";
import "react-pro-sidebar/dist/css/styles.css";
import styled from "styled-components";
import ConnecButton from "./ConnecButton";

function Footer(props) {
    const { setCollapse, collapse, toggle, setToggle } = props;
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width: 600px)");
    collapse && !isMobile
        ? (document.body.style.overflow = "hidden")
        : (document.body.style.overflow = "auto");

    return (
        <>
       
            <FooterSt>
            <Flex flexDirection="column" alignItems="center"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "16px",
                                }}
                            >
                                <Text fontSize="48px" color="yellow" bold>Join our Telegram</Text>
                                <Text fontSize="48px" color="orange" bold>Community!</Text>
                                </Flex>             
                <FooterContent>
                    <FooterDivider />
                    <FooterMenu className="pgsection">
                        <FooterLogo src="/images/logo.png" alt="Logo" />                    
                    </FooterMenu>
                    <FooterMenu className="pgsection">
                        <FooterNav>
                        <ul>
                                <li>
                                    <a href="#home">Telegram</a>
                                </li>
                                {/*<li>
                                    <a href="#ecosystem">Ecosystem</a>
                                </li>*/}
                                <li>
                                    <a href="#auction"></a>
                                </li>
                                <li>
                                    <a href="#pulse-miner"></a>
                                </li>
                                <li>
                                    <a href="#home">X [Twitter]</a>
                                </li>
                                {/*<li>
                                    <a href="#ecosystem">Ecosystem</a>
                                </li>*/}
                                <li>
                                    <a href="#auction">The HUB</a>
                                </li>
                                <li>
                                    <a href="#pulse-miner">Lite Paper</a>
                                </li>
                                <li>
                                    <a href="#home">FAQs</a>
                                </li>
                                {/*<li>
                                    <a href="#ecosystem">Ecosystem</a>
                                </li>*/}
                                <li>
                                    <a href="#auction">Contract Address</a>
                                </li>
                                <li>
                                    <a href="#pulse-miner">White Paper</a>
                                </li>                                                                
                            </ul>
                        </FooterNav>
                    </FooterMenu>
                   
                    <FooterBottom className="pgsection">
                        <FooterCopyright>
                            © 2013 TruDeFi All rights reserved.
                        </FooterCopyright>
                        <FooterLinks>
                            <a href="#whitepaper">Cookies</a>
                            {/*<a href="address">Contract Address</a>*/}
                            <a href="#privacy">Privacy Statement</a>
                        </FooterLinks>
                    </FooterBottom>                    
                </FooterContent>
            </FooterSt>
        </>
    );
}

const FooterSt = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(34, 31, 29, 0.6);
    width: 100%;

    position: relative;
    z-index: -1;
    // margin-top: 50px;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 30px;
    padding-right: 160px;
    padding-left: 160px;
    @media screen and (max-width: 1250px) {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }
    @media screen and (max-width: 991px) {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }
    @media screen and (max-width: 600px) {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }
`;

const FooterContent = styled.div`
    width: 100%;

`;

const FooterMenu = styled.div`
    margin-top: 43px;
    display: inline-block
    gap: 200px;
    width: 100%;
    justify-content: space-between;  
    
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

const FooterLogo = styled.img`
    width: 83px;
    aspect-ratio: 1.49;
    object-fit: cover;
`;

const FooterNav = styled.nav`
    ul {
        
        display: flex;
        gap: 20px;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);  
        width: 60%;    
    }
    li {
        color: #f1ede9;
        font-weight: 600;
    }
    @media screen and (max-width: 600px) {
        ul {
            padding-left: 0 !important;
        }
    }
`;

const FooterDivider = styled.div`
    border: 1px solid rgba(65, 59, 55, 0.4);
    width: 100%;
    margin-top: 43px;
`;

const FooterBottom = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
    margin-top: 44px;
    color: rgba(199, 185, 178, 0.8);
    font-weight: 400;

    ul {
        display: flex;
        gap: 20px;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);  
        width: 0%;    
    }
    li {
        color: #f1ede9;
        font-weight: 600;
    }

    @media (max-width: 991px) {
        flex-wrap: wrap;
        margin-top: 40px;
    }
`;

const FooterCopyright = styled.div`
    flex: 1;
`;

const FooterLinks = styled.div`
    display: flex;
    gap: 16px;
    white-space: nowrap;

    @media (max-width: 991px) {
        white-space: initial;
    }
`;
export default Footer;
