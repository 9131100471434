import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers, BigNumber } from "ethers";
import { Flex } from "crox-new-uikit";
import useMediaQuery from '@mui/material/useMediaQuery';
import { WalletConnect } from "../../../components/Gadget";
import { fenixContract } from "../../../libraries/fenixContract";
import { helperContract } from "../../../libraries/helperContract";
import { minerContract } from "../../../libraries/minerContract";
import { NFTGAME_CONTRACT_ADDRESS, transformCharacterData } from "../../../utils/constants";
import {
    Chain,
    Address,
    useContractWrite,
    useNetwork,
    usePrepareContractWrite,
    useWaitForTransaction,
    useFeeData,
    useBalance,
    useAccount,
    useContractReads,
  } from "wagmi";
import SelectCharacter from "./SelectCharacter";
//import Init from '../../../components/Gadget/Init'
import myEpicGame from '../../../utils/MyEpicGame.json';
//import InitNFTs from "../../InitNFTs";

import awesomeGame from '../../../utils/StakingToken.json';
import './selectcharacter.scss'
import FENIX_ABI from "../../../models/abi/StakingToken.json"; 
import HELPER_ABI from "../../../models/abi/HelperToken.json"; 
import MINER_ABI from "../../../models/abi/MinerToken.json"; 


import img1 from "../../../assets/img/1.jpg";
import img2 from "../../../assets/img/2.jpg";
import img3 from "../../../assets/img/x1.jpg";
import img4 from "../../../assets/img/x2.jpg";
import img5 from "../../../assets/img/5.jpg";
import img6 from "../../../assets/img/6.jpg";
import img7 from "../../../assets/img/7.jpg";
import img8 from "../../../assets/img/8.jpg";
import logo from "../../../assets/img/logo.png";

function Character(props) {
    const { collapse } = props
    const isMobile = useMediaQuery("(max-width: 600px)")
    const [characterNFT, setCharacterNFT] = useState([]);
    const [bonusWallets, setBonusWallets] = useState([]);
    const [lastDayEntered, setLastDayEntered] = useState([]);
    const [currentDay, setCurrentDay] = useState(0);
    const [enterTypes, setEnterTypes] = useState([1, 4, 5]);

    //const router = useRouter();
    const { chain } = useNetwork();
    const { address } = useAccount();
    const { data: feeData } = useFeeData({ formatUnits: "gwei", watch: false, cacheTime: 60_000 });
    const { data: fenixBalance } = useBalance({
      address: address,
      //token: fenixContract(chain).address,
      staleTime: 20_000,
    });

    //console.log(fenixContract(chain));
    var { data: txn, refetch:refetchAssets } = useContractReads({
        contracts: [
          {
            ...fenixContract(chain),
            functionName: "fetchAssets",
            args: [address, 0, 3, 1]
          },             
          {
            ...fenixContract(chain),
            functionName: "currentDay",
          },  
          {
            ...fenixContract(chain),
            functionName: "fetchAssets",
            args: ["0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266", 0, 3, 0]
          },                                                                    
        ],
        cacheTime: 300000,
        watch: false,
      });


      var { data: _lastDayEntered, refetch:refetchLastDay } = useContractReads({
        contracts: enterTypes?.map((_type) =>    
        ({
              ...fenixContract(chain),
              functionName: "lastDayEntered",
              args: [_type, address],     
          })
        ),
        cacheTime: 300000,
        watch: false,
      });






      useEffect(() => {
        const fetchNFTMetadata = async () => {
            //console.log('Checking for Character NFT on address:', address);

            console.log("TXN", txn);
            console.log("FENIX_ABI", FENIX_ABI);
            console.log("HELPER_ABI", HELPER_ABI);
            console.log("MINER_ABI", MINER_ABI);

            let cNFT = [];
            let bonusWs = [];
            //if(txn?.[0]?.[0]?.length == 1) cNFT = [txn?.[0]?.[1]];
            if(txn?.[0]?.[1]?.length >= 1) cNFT = txn?.[0]?.[2];
            if(txn?.[2]?.[1]?.length >= 1) bonusWs = txn?.[2]?.[2];

            if (cNFT.length > 0) {
                //console.log('User has character NFT');
                setCharacterNFT(cNFT)
                if (bonusWs?.length > 0) {
                  //console.log('Bonus wallets found');
                  setBonusWallets(bonusWs)
                  
              }                
                
            } else {
                //console.log('No Character NFT Found');
            }


            if (txn?.[1]?.toNumber() > 0)
              setCurrentDay(txn?.[1]?.toNumber())
            if (_lastDayEntered?.length > 0)
              setLastDayEntered(_lastDayEntered)
        }

        if (address) {
            fetchNFTMetadata();
        }
    }, [address, txn])




    return (
      <>
      
      {/*
        <Flex className={!collapse ? "container" : "container wider-width"} justifyContent='center' flexDirection='column' mt={isMobile ? '20px' : '50px'} >
            {!address ? (
                <Flex justifyContent='center' flexDirection='column' alignItems='center' mt={isMobile ? 100 : -30}>

                </Flex>

            ) : (
                <Flex justifyContent='center' alignItems='center' mt={isMobile ? 30 : -50}>
                    <SelectCharacter bonusWallets={bonusWallets} setCharacterNFT={setCharacterNFT} characterNFT={characterNFT} currentDay={currentDay} lastDayEntered={lastDayEntered} refetchAssets={refetchAssets} refetchLastDay={refetchLastDay}/>
                </Flex>
            )}
        </Flex>
        */}
        <Flex flexDirection='column' style={{ backgroundImage:"url(" + img3 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
        <SelectCharacter bonusWallets={bonusWallets} setCharacterNFT={setCharacterNFT} characterNFT={characterNFT} currentDay={currentDay} lastDayEntered={lastDayEntered} refetchAssets={refetchAssets} refetchLastDay={refetchLastDay}/>
        </Flex>
        </>
    )



}

export default Character




/*
characterNFT.length == 0 ? (
    <Flex justifyContent='center' alignItems='center' mt={isMobile ? 30 : -50}>
        <InitNFTs />
    </Flex>
) : 
*/