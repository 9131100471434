import SeedSale from "./seedSale.js";
import imgBG20 from "../../assets/img/trudefi/bg/20.jpg";
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { Box, Divider, Grid, Col } from '@mantine/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
{
    faCircleDown
} from "@fortawesome/free-solid-svg-icons";
import Typewriter from 'typewriter-effect';

import imgHOW10 from "../../assets/img/howto/10.png";
import vid6 from "../../assets/img/videos/Media6.mp4"
import imgREF7 from "../../assets/img/referrals/7.png";

import styled from "styled-components";
// Homepage Section2 Section
export default function Section4()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG20 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
              <div className="text-center">
                <div  className="justify-content-center">

                  <div class="container">
                    <div class="col px-4 px-xl-2 mt-5 justify-content-center">

                      <div class="row px-4 px-xl-2 mt-5 justify-content-center">
                        <div class="col-md-7 mb-5 text-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">
                        <Flex mb="10px" justifyContent="center" flexDirection="column" alignItems="left" width="100px">
                          <Text fontSize="18px" color="#fff" bold mr="14px" >Some good-to-hear-at-least-once Information for you.                          </Text>    
                        </Flex>              
                        <Flex mt="1px" mb="50px" justifyContent="center" flexDirection="column" alignItems="center" width="100px">
                          <Text fontSize="13px" color="#fff" mr="14px" >
                          For 45 days on the Tru2X protocol for PLS, you will enjoy earning 10% of your referrals' Tru2X shares. Specifically, you'll receive 10% as a referrer and 5% as a referee of Tru2X shares from wallet deposits to the Auction, excluding transfers of accrued dividends from ongoing miners (compounds).

                          <br/><br/>These valuable Tru2X Share-Bonuses are stored in a referral card. You can redeem referral bonus shares in increments or at once and deploy miners with them. Every bonus can be either redeemed to one’s wallet address or another active participant´s wallet address.

                          <br/><br/>Redeemed Tru2X Shares become regular miners which receive their daily dividends proportionally to all other Shares in the mining pool allocated until a 2X is achieved. Bonus-Share miners receive their PLS value based on the price of the day they are redeemed. A fully 2X-matured bonus share miner can, if deployed on a day with a higher share price in the Auction House, achieve more than 20% of the deposit amount they derive from.

                          <br/><br/>To receive a referral ID, one must have entered a deposit at least once into the Auction House.

                          <br/><br/>The TruDeFi.io team wishes you the best of success and will assist you with competent replies to your inquiries, requests and suggestions through our engaged admin team.
                          </Text>    
                        </Flex>      
                        </div>

                        <div class="col-md-5 mb-5 text-left" data-scroll-reveal="enter bottom move 30px over 0.5s after 0.2s">
                          <div class="video-section hover-target">
                                    <figure> 
                                      <iframe width="500" height="300" src={vid6} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </figure>
                            </div>	
                          </div>
                      </div>	
                      <div class="row ">
                        <div class="text-center text-xl-left" data-scroll-reveal="enter left move 30px over 0.5s after 0.1s">
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-5 mb-5">
                              <div class="img-wrap">
                                  <img src={imgREF7} alt=""/>
                              </div>	
                          </div>	
                        </div> 
                      </div>                      
                    </div>		
                  </div>

                </div>
              </div>
            </Flex>
        </>
    )
};

const Button = styled.button`
    border-radius: 8px;
    padding: 10px 18px;
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: ${({ primary }) => (primary ? "#d9511c" : "#171615")};
    color: ${({ primary }) => (primary ? "#f1ede9" : "#d9511c")};
    border: ${({ secondary }) => secondary && "1px solid #d9511c"};
`;