
const Addr = "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6";
//const Addr = "0xF565Eb37B2dE2135498A0db3b3bc0cAa09F6a3cB";

const addresses  = {
    contractAddress: Addr,
    minerAddress: Addr,
    helperAddress: Addr
}

//const contractAddress = "0x4ff1f64683785E0460c24A4EF78D582C2488704f";
//const minerAddress = "0x4ff1f64683785E0460c24A4EF78D582C2488704d";
//const helperAddress = "0xe044814c9eD1e6442Af956a817c161192cBaE98F";
//const contractAddress = "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0";

export default addresses;