import { Address, Chain } from "wagmi";
import {
  avalanche,
  bsc,
  evmos,
  fantom,
  foundry,
  goerli,
  localhost,
  mainnet,
  moonbeam,
  polygon,
  polygonMumbai,
  dogechain,
  okc,
  base,
  pulsechainV4,
  //pulsechain,
} from "@wagmi/chains";

import LOBBY_ABI from "../models/abi/StakingToken.json";
//import HELPER_ABI from "../models/abi/StakingToken.json";
//import MINER_ABI from "../models/abi/StakingToken.json";
import addresses from "./contracts-config";
const contractAddress = addresses.contractAddress;
//import FENIX_ABI from "@/models/abi/FENIX_ABI";
//import { ethereumPoW, x1Devnet } from "@/libraries/chains";



export const fenixContract = (contractChain?: Chain) => {
  //console.log("contractChain", contractChain)
  switch (contractChain?.id) {
    case foundry.id:
    //case localhost.id:
      return {
        //address: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
        address: contractAddress,
        abi: LOBBY_ABI.abi,
        chainId: contractChain.id,
        decimals: 9,
      };
    case goerli.id:
      return {
        //address: "0x5bD859C89d626e9E2aA96e5c1f0E80360Aa64212",
        address: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
        //abi: LOBBY_ABI.abi,
        abi: LOBBY_ABI.abi,
        chainId: contractChain.id,
      };
    case polygonMumbai.id:
      return {
        address: "0x029cE5736854Ca1e064A7822a17657E274E805a7",
        abi: LOBBY_ABI.abi,
        chainId: contractChain.id,
      };

    case pulsechainV4.id:
      return {
        address: "0x32239533850c6f9c83Af6c363C6008AAB5437950",
        abi: LOBBY_ABI.abi,
        chainId: contractChain.id,
      };
    case mainnet.id:
    case polygon.id:
    case bsc.id:
    case avalanche.id:
    case moonbeam.id:
    case fantom.id:
    case dogechain.id:
    case okc.id:
    //case pulsechain.id:
    //  return {
    //    address: "0xC3e8abfA04B0EC442c2A4D65699a40F7FcEd8055",
    //    abi: LOBBY_ABI.abi,
    //    chainId: contractChain.id,
    //  };
    case evmos.id:
      return {
        address: "0x7c27d2D2044FE90Cb98f5ECdc235839FdE740124",
        abi: LOBBY_ABI.abi,
        chainId: contractChain.id,
      };
    case base.id:
      return {
        address: "0x07FdE3eD7727c1D84171A6e5815964d50827CF69",
        abi: LOBBY_ABI.abi,
        chainId: contractChain.id,
      };
    default:
      return {
        address: contractAddress,
        abi: LOBBY_ABI.abi,
        //helper_address: helperAddress,
        //helper_abi: HELPER_ABI.abi,
        //miner_address: minerAddress,
        //miner_abi: MINER_ABI.abi,                
      };
  }
};
