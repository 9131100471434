// Import the FontAwesomeIcon component
import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { Box, Divider, Grid, Col } from '@mantine/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
{
  faTelegram
} from "@fortawesome/free-brands-svg-icons";
import imgBG16 from "../../assets/img/trudefi/bg/16.jpg";
import imgREF1 from "../../assets/img/referrals/1-1.png";

import '../styles/inner.css';

// Homepage Home Section
export default function HomeSection()
{
    return (
        <>
            {/* PARALLAX ONE START */}

            <Flex className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundImage:"url(" + imgREF1 + ")", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}>
            <div class="shadow-title parallax-top-shadow">Referrals</div>
		
		<div class="section padding-bottom over-hide z-bigger" style={{padding: "55px"}}>
			<div class="container z-bigger">
				<div class="row page-title px-5 px-xl-2">
					<div class="px-36 col-12 parallax-fade-top">
						<h1>Referrals</h1>
					</div>




                    
                <div className="container mx-auto text-center ">


                    <div class="container">
	
      <div class="column px-5 px-xl-2 justify-content-center">
      <div class="container mt-0">
      <Grid mt="0px">
      <Col span={6} md={3} lg={3} style={{ color: "black", fontWeight: 600 }} >
        <strong>✓</strong> On-ramp Funds        
      </Col>
      <Col span={6} md={3} lg={3} style={{ color: "black", fontWeight: 600 }} >
      <strong>✓</strong> Live Assistance	        
      </Col>
      <Col span={6} md={3} lg={3} style={{ color: "black", fontWeight: 600 }} >
      <strong>✓</strong> White/Lite Paper	        
      </Col>
      <Grid.Col span={6} md={3} lg={3} style={{ color: "black", fontWeight: 600 }} >
  
                    <a href="https://t.me/R3D4NG3L" target="_blank" className="bg-neutral-900 text-white hover:bg-red-600 active:bg-red-900 font-bold uppercase text-base px-8 py-3 rounded-[24px] shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                        <span>PLS Lobby | Miner <FontAwesomeIcon icon={faTelegram} className="ml-2"/></span>
                    </a>                          
      </Grid.Col>
    </Grid>           
    </div>



    
    </div>
    
		</div>  

                </div>
                





				</div>
			</div>
		</div>
        </Flex>   



 
         
            {/* PARALLAX ONE END */}
        </>
    )
}