import Typewriter from 'typewriter-effect';

import { Link, Flex, Text, Input } from 'crox-new-uikit';
import { Box, Divider, Grid, Col } from '@mantine/core';

import imgBG15 from "../../assets/img/trudefi/bg/15.jpg";
import img2X10 from "../../assets/img/tru2x/10.jpg";
import img2X11 from "../../assets/img/tru2x/11.jpg";
import img2X12 from "../../assets/img/tru2x/12.jpg";
import img2X13 from "../../assets/img/tru2x/13.jpg"

export default function Section9()
{
    return (
        <>
            <Flex justifyContent="center" alignItems="center" className="section full-height over-hide swiper-container z-bigger" flexDirection="column" style={{ backgroundAttachment: "fixed", backgroundImage:"url(" + imgBG15 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "15px"}}>
                <div className="text-center">

                <div class="container mt-50">

<Grid mt="50px">
<Col span={12} md={6} lg={5}>
    <div class="img-wrap">
                  <img src={img2X10} alt=""/>
              </div>	        
</Col>
<Col span={12} md={6} lg={4}>
  <h5 class="mb-3">Fine-Tuned Maximum Caps to operate TRUly Sustainable</h5>
  <p class="mb-0 pb-0">If users don´t end their miner early, EVERY DEPOSIT will receive its 2X ROI. Backed by externally generated revenue, a daily gradually increasing yield is added to he Auction House and contributes to an ensured 2X ROI.</p>

</Col>
<Col span={12} md={6} lg={3}></Col>
<Col span={12} md={6} lg={3}></Col>
<Col span={12} md={6} lg={4}>
    <h5 class="mb-3">Fine-Tuned Maximum Caps to operate TRUly Sustainable</h5>
    <p class="mb-0 pb-0">If users don´t end their miner early, EVERY DEPOSIT will receive its 2X ROI. Backed by externally generated revenue, a daily gradually increasing yield is added to he Auction House and contributes to an ensured 2X ROI.</p>

</Col>
<Col span={12} md={6} lg={5}>
    <div class="img-wrap">
                  <img src={img2X11} alt=""/>
              </div>	        
</Col>
<Col span={12} md={6} lg={5}>
    <div class="img-wrap">
                  <img src={img2X12} alt=""/>
              </div>	        
</Col>
<Col span={12} md={6} lg={4}>
  <h5 class="mb-3">Fine-Tuned Maximum Caps to operate TRUly Sustainable</h5>
  <p class="mb-0 pb-0">If users don´t end their miner early, EVERY DEPOSIT will receive its 2X ROI. Backed by externally generated revenue, a daily gradually increasing yield is added to he Auction House and contributes to an ensured 2X ROI.</p>
</Col>
<Col span={12} md={6} lg={3}></Col>
<Col span={12} md={6} lg={3}></Col>      
<Col span={12} md={6} lg={4}>
  <h5 class="mb-3">Fine-Tuned Maximum Caps to operate TRUly Sustainable</h5>
  <p class="mb-0 pb-0">If users don´t end their miner early, EVERY DEPOSIT will receive its 2X ROI. Backed by externally generated revenue, a daily gradually increasing yield is added to he Auction House and contributes to an ensured 2X ROI.</p>
  </Col>            
<Col span={12} md={6} lg={5}>
    <div class="img-wrap">
                  <img src={img2X13} alt=""/>
              </div>	        
</Col>


</Grid>


  </div>

                </div>
            </Flex>
        </>
    )
}